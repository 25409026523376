import React, { type FC } from 'react'

import { clsx } from 'clsx'
import { Image } from 'react-bootstrap'

import styles from './FlagWrapper.module.css'
import { type FlagWrapperProps } from './FlagWrapper.types'
import { validCountryCodes } from './FlagWrapper.utils'

export const FlagWrapper: FC<FlagWrapperProps> = ({ countryShortCode, ...restProps }) => {
    if (countryShortCode == null || countryShortCode === '') return null

    const flagFile = validCountryCodes.includes(countryShortCode) ? countryShortCode : 'PH'

    return <Image src={`/static/assets/countries/${flagFile}.svg`} className={clsx(styles.FlagWrapper)} {...restProps} />
}

export default FlagWrapper
