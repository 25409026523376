import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type AboutUsProps } from './AboutUs.types'

const LazyComponent = lazy(async () => await import('./AboutUs'))

export const AboutUs = (props: JSX.IntrinsicAttributes & AboutUsProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default AboutUs
