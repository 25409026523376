import React, { type FC } from 'react'

import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Timeline } from 'react-twitter-widgets'

import { useAppState } from '20-lib/hooks'

import { useConfigContext } from '09-providers/Config/Config.context'

import { ContactUs } from './ContactUs/ContactUs'
import { type FooterProps } from './Footer.types'

import './Footer.css'

export const Footer: FC<FooterProps> = () => {
    const { Config } = useConfigContext()
    const appContext = useAppState()

    const fbPageHandle = encodeURIComponent(appContext.geoLocalize || appContext.isNotGuy ? 'FilipinaLuv' : 'Filipinalove')

    return (
        <div className={clsx('footer')}>
            <Container>
                <Row>
                    <Col xs={0} sm={0} md={12} lg={12}>
                        <div className={clsx('scroll-to-top')}>
                            <Button variant='link' href='#top' title='Go to Top' className={clsx('cd-top')} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <iframe
                            loading='lazy'
                            title='Our Facebook Feed'
                            id='fb-embedded'
                            src={`https://www.facebook.com/plugins/page.php?href=${fbPageHandle}&tabs=timeline&width=340&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=${
                                Config.social.facebook.key ?? 'MISSING'
                            }`}
                            height={400}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Timeline
                            dataSource={{
                                sourceType: 'profile',
                                screenName: 'FindFilipinaLuv'
                            }}
                            options={{
                                height: '400'
                            }}
                        />
                    </Col>

                    <Col xs={12} lg={4}>
                        <ContactUs />
                    </Col>
                </Row>
                <div className={clsx('footer-bottom')}>
                    <Row>
                        <Col>
                            <div className={clsx('footer-menu')}>
                                <p>
                                    <span>&copy; Up to 2024, filipinaluv.com. All rights reserved.</span>
                                </p>
                                <p>
                                    <span>FilipinaLuv is not a conventional Dating App. We do not intend to become one.</span>
                                </p>
                                <p>
                                    <span>
                                        <LinkContainer to='/terms'>
                                            <Button variant='link'>Terms of Use</Button>
                                        </LinkContainer>
                                    </span>
                                    <span>
                                        <LinkContainer to='/privacypolicy'>
                                            <Button variant='link'>Privacy Policy</Button>
                                        </LinkContainer>
                                    </span>
                                    <span>
                                        <LinkContainer to='/cookies'>
                                            <Button variant='link'>Cookie Policy</Button>
                                        </LinkContainer>
                                    </span>
                                    <span>
                                        <LinkContainer to='/sitemap'>
                                            <Button variant='link'>Sitemap</Button>
                                        </LinkContainer>
                                    </span>
                                    <span>
                                        <LinkContainer to='FAQ-Photos-Pictures-Why-to-like-Filipino-Woman.html'>
                                            <Button variant='link'>F.A.Q.</Button>
                                        </LinkContainer>
                                    </span>
                                    <span>
                                        <LinkContainer to='/aboutus'>
                                            <Button variant='link'>About Us</Button>
                                        </LinkContainer>
                                    </span>
                                </p>
                                <p>
                                    <span>Android, Google Play and the Google Play logo are trademarks of Google Inc.</span>
                                </p>
                                <p>
                                    <span>
                                        [
                                        <Button variant='link' className={clsx('network')} href='http://filipinaluv.com'>
                                            FilipinaLuv
                                        </Button>
                                        ]
                                    </span>
                                    <span>
                                        [
                                        <Button variant='link' className={clsx('network')} href='http://filipinoluv.com'>
                                            FilipinoLuv
                                        </Button>
                                        ]
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className={clsx('footer-icons-list')}>
                                <li>
                                    <Button variant='link' href='https://facebook.com/FilipinaLuv' target='_blank'>
                                        <span className={clsx('contact-ic')}>
                                            <FontAwesomeIcon icon={faFacebookF} size='xl' />
                                        </span>
                                    </Button>
                                </li>
                                <li>
                                    <Button variant='link' href='https://twitter.com/FindFilipinaLuv' target='_blank'>
                                        <span className={clsx('contact-ic')}>
                                            <FontAwesomeIcon icon={faTwitter} size='xl' />
                                        </span>
                                    </Button>
                                </li>
                                <li>
                                    <Button variant='link' href='/cdn-cgi/l/email-protection#ff96919990bfb99693968f96919eb38a89d19c9092' target='_blank'>
                                        <span className={clsx('contact-ic')}>
                                            <FontAwesomeIcon icon={faEnvelope} size='xl' />
                                        </span>
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Footer
