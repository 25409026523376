import React, { lazy, Suspense, type SuspenseProps } from 'react'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { type LoginProps } from './Login.types'

const LazyComponent = lazy(async () => await import('./Login'))

export const Login = (props: JSX.IntrinsicAttributes & LoginProps & SuspenseProps): JSX.Element => (
    <Suspense fallback={<LoadingPage source='Login.lazy.tsx' />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Login
