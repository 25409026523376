import React, { type FC } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { type AgeSliderProps } from './AgeSlider.types'
import { DefaultSearchFilter } from '20-lib/constants/common'

export const AgeSlider: FC<AgeSliderProps> = ({ low, high, onChangeHighAge, onChangeLowAge }) => {
    // Ensure low and high are always treated as numbers
    const lowNumber = low != null ? Number(low) : DefaultSearchFilter.lowAge
    const highNumber = high != null ? Number(high) : DefaultSearchFilter.highAge

    const highLow = highNumber > lowNumber ? highNumber : DefaultSearchFilter.highAge
    const adjustedLow = lowNumber
    const adjustedHigh = highLow
    const ageRange = [adjustedLow, adjustedHigh]

    const handleChange = (value: number | number[]) => {
        if (Array.isArray(value) && value.length === 2) {
            onChangeLowAge(value[0])
            // treat 60 as 60+ which is 99 on the backend
            onChangeHighAge(value[1] >= 60 ? 99 : value[1])
        }
    }

    return (
        <>
            <Slider range value={ageRange} min={DefaultSearchFilter.lowAge} max={Math.min(DefaultSearchFilter.highAge, 60)} allowCross={false} onChange={handleChange} />
            <div>
                Between Age Range of: {ageRange[0]} and {ageRange[1] >= 60 ? '60+' : ageRange[1]}
            </div>
        </>
    )
}

export default AgeSlider
