import React, { type FC, useEffect, useRef, useState } from 'react'

import { Conditional } from '01-atoms/Conditional/Conditional'

import styles from './VisibilitySensor.module.css'
import { type VisibilitySensorProps } from './VisibilitySensor.types'

export const VisibilitySensor: FC<VisibilitySensorProps> = ({ children }) => {
    const visibilityRef = useRef<HTMLDivElement>(null)
    const [_observer, setObserver] = useState<IntersectionObserver>()

    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting)
        })

        setObserver(observer)

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        if (visibilityRef.current != null && _observer != null) _observer.observe(visibilityRef.current)
    }, [_observer, visibilityRef])

    useEffect(() => {
        if (isIntersecting) _observer?.disconnect()
    }, [_observer, isIntersecting])

    return (
        <div className={styles.VisibilitySensor} ref={visibilityRef}>
            <Conditional condition={isIntersecting}>{children}</Conditional>
        </div>
    )
}

export default VisibilitySensor
