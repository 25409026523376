import React from 'react'

import { faBell, faComments, faEye, faHeart, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NotificationsMenu } from '05-materials/NotificationsMenu/NotificationsMenu'

import { type AuthenticatedNavBarMenuItems } from './AuthenticatedNavBar.types'

export const menuItems: AuthenticatedNavBarMenuItems = {
    default: {
        notificationsMenu: {
            type: 'component',
            children: <NotificationsMenu />
        },

        notificationsLink: {
            type: 'link',
            link: { pathname: '/notifications' },
            title: (
                <>
                    <FontAwesomeIcon icon={faBell} aria-hidden='true' /> Notifications
                </>
            )
        },

        search: {
            type: 'link',
            link: { pathname: '/find-my-cupid-match' },
            title: (
                <>
                    <FontAwesomeIcon icon={faSearch} aria-hidden='true' /> My Search
                </>
            )
        },

        chats: {
            type: 'link',
            link: '/chat',
            title: (
                <>
                    <FontAwesomeIcon icon={faComments} aria-hidden='true' /> My Chats
                </>
            )
        },

        likes: {
            type: 'link',
            link: '/likes',
            title: (
                <>
                    <FontAwesomeIcon icon={faHeart} aria-hidden='true' /> My Likes
                </>
            )
        },

        visitors: {
            type: 'link',
            link: '/visits',
            title: (
                <>
                    <FontAwesomeIcon icon={faEye} aria-hidden='true' /> My Visitors
                </>
            )
        },

        credits: {
            type: 'link',
            link: '/credits',
            title: <>Credits</>
        },

        settings: {
            type: 'link',
            link: '/settings',
            title: <>Settings</>
        },

        divider: {
            type: 'divider'
        }
    },

    groupA: {
        myProfile: {
            type: 'link',
            link: '/my-profile',
            title: <>My Profile</>
        },

        datingTips: {
            type: 'link',
            link: '/how-to-date-a-filipina',
            title: <>Dating Tips</>
        },

        plans: {
            type: 'link',
            link: '/plans',
            title: <>Plans</>
        },

        premium: {
            type: 'link',
            link: '/premium',
            title: <>Premium</>
        },

        executive: {
            type: 'link',
            link: '/executive',
            title: <>Executive</>
        },

        logout: {
            type: 'link',
            link: '#',
            title: <>Logout</>
        }
    },

    groupB: {
        myProfile: {
            type: 'link',
            link: '/post-profile-and-earn',
            title: <>My Profile</>
        },

        datingTips: {
            type: 'link',
            link: '/kung-paano-makipag-date-sa-isang-dayuhan',
            title: <>Dating Tips</>
        },

        kaPartner: {
            type: 'link',
            link: '/ka-partner',
            title: <>Ka-Partner</>
        },

        invite: {
            type: 'link',
            link: '/invite-and-earn-five-us-dollars',
            title: <>Invite New Member!</>
        }
    },

    groupC: {
        admin: {
            type: 'link',
            link: '/admin',
            title: <>Admin</>
        }
    }
}
