import React, { type FC } from 'react'

import clsx from 'clsx'
import FadeLoader from 'react-spinners/FadeLoader'

import { getDebugger } from '20-lib/debug'

import styles from './Loading.module.css'
import { type LoadingProps } from './Loading.types'

const debug = getDebugger('molecule:Loading:Loading')

export const Loading: FC<LoadingProps> = ({ colour, className, source }) => {
    if (source != null) debug.log(`called from ${source}`)

    return (
        <div className={clsx(styles.flLoader, className)}>
            <div className={styles.animContainer}>
                <FadeLoader height={24} width={5} radius={10} margin={3} color={colour ?? '#F5A623'} />
            </div>
        </div>
    )
}

export default Loading
