import React, { type FC, useEffect, useMemo, useState } from 'react'

import { ScrollBlockerContext } from './ScrollBlocker.context'
import { type ScrollBlockerProviderProps } from './ScrollBlockerProvider.types'

export const ScrollBlockerProvider: FC<ScrollBlockerProviderProps> = ({ children }) => {
    const [_scrollBlockerState, setScrollBlockerState] = useState<boolean>(false)

    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.overflow = _scrollBlockerState ? 'hidden' : ''
        document.getElementsByTagName('body')[0].style.marginRight = _scrollBlockerState ? `${scrollBarWidth}px` : ''
    }, [_scrollBlockerState, scrollBarWidth])

    useEffect(() => {
        return () => {
            document.getElementsByTagName('body')[0].style.overflow = ''
            document.getElementsByTagName('body')[0].style.marginRight = ''
        }
    }, [])

    const scrollBlockerContext = useMemo(() => {
        return { setScrollBlockerState }
    }, [setScrollBlockerState])

    return (
        <ScrollBlockerContext.Provider key='ScrollBlockerProvider' value={scrollBlockerContext}>
            {children}
        </ScrollBlockerContext.Provider>
    )
}

export default ScrollBlockerProvider
