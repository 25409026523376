import React, { type FC } from 'react'

import clsx from 'clsx'

import { getLikedAltTextFromPerson, getLikedIconFromPerson } from '20-lib/ui'

import actionButtonStyles from '10-styles/ActionButton.module.css'

import styles from './LikeProfileButton.module.css'
import { type LikeProfileButtonProps } from './LikeProfileButton.types'

export const LikeProfileButton: FC<LikeProfileButtonProps> = ({ person, onClick }) => (
    <div className={clsx(actionButtonStyles.ActionButtonWrapper, styles.ActionButtonWrapper)}>
        <button className={clsx(actionButtonStyles.ActionButton, styles.LikeProfileButton)} onClick={onClick}>
            <img
                className={clsx(actionButtonStyles.ActionButtonIcon, styles.LikeProfileButtonIcon)}
                aria-hidden='true'
                src={getLikedIconFromPerson(person)}
                alt={getLikedAltTextFromPerson(person)}
            />
        </button>
    </div>
)

export default LikeProfileButton
