/**
 * Check if email is valid
 * @prop String email
 * @returns Boolean
 */
const validEmailRe =
    /^("?[a-z0-9]+([+.-][a-z0-9][\w!#$%&'*/=?^`{|}~+]*)*"?)@((\[?(\d{1,3}\.){3}\d{1,3}\]?)|(\[?([a-f\d]{1,4}:)+(:[a-f\d]{1,4})\]?)|(([a-z0-9][\w-]*\.)+[a-z]{2,}))$/i

export const isEmail = (email: string): boolean => {
    if (email == null) throw new Error('isEmail received invalid input')

    return validEmailRe.test(email.trim())
}

/**
 * Check if variable is empty
 * @prop String thing
 * @returns Boolean
 */
export const isEmpty = (thing: string | number | object | null | undefined): boolean => {
    let empty = false

    switch (typeof thing) {
        case 'undefined':
            empty = true
            break
        case 'string':
            if (thing.trim().length === 0) {
                empty = true
            }
            break
        case 'object':
            if ((Array.isArray(thing) && thing.length === 0) || thing === null || Object.keys(thing).length === 0) {
                empty = true
            }
    }

    return empty
}

interface IsLengthOptions {
    trim?: boolean
    lt?: number
    lte?: number
    gt?: number
    gte?: number
}

/**
 * Check length of the string greater than
 * @prop String|Integer str
 * @prop boolean|options.trim Trim input before validating
 * @prop number|options.lt Check if length less than lt
 * @prop number|options.lte Check if length is less than or equals to lte
 * @prop number|options.gt Check if length is greater than gt
 * @prop number|options.gte Check if length is greater than or equals to gte
 * @returns Boolean
 */
export const isLength = (str: string, options: IsLengthOptions): boolean => {
    if (isEmpty(options)) {
        throw new Error('Who will provide the options you?')
    }

    let isValid = true

    if (!['string', 'number'].includes(typeof str)) {
        isValid = false
    } else {
        // Convert to string incase it's number
        let len = 0

        if (options.trim ?? false) {
            len = str.toString().trim().length
        } else {
            len = str.toString().length
        }

        if (
            (typeof options.lt === 'number' && len >= options.lt) ||
            (typeof options.lte === 'number' && len > options.lte) ||
            (typeof options.gt === 'number' && len <= options.gt) ||
            (typeof options.gte === 'number' && len < options.gte)
        ) {
            isValid = false
        }
    }

    return isValid
}

/**
 * Check if string contains whitespaces
 * @prop String str
 * @returns Boolean
 */
export const isContainWhiteSpace = (str: string): boolean => {
    if (typeof str === 'string' || typeof str === 'number') {
        return str.toString().trim().includes(' ')
    } else {
        return false
    }
}
