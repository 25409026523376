import React, { type FC } from 'react'

import clsx from 'clsx'
import { Col, Container, Row } from 'react-bootstrap'

import { getDebugger } from '20-lib/debug'

import { Loading } from '02-molecules/Loading/Loading'

import styles from './LoadingPage.module.css'
import { type LoadingPageProps } from './LoadingPage.types'

const debug = getDebugger('materials:LoadingPage:LoadingPage')

export const LoadingPage: FC<LoadingPageProps> = ({ children, loading, source }) => {
    loading = loading ?? true

    if (loading) {
        if (source != null) debug.log(`called from ${source}`)

        return (
            <Container fluid className={clsx(styles['loading-page'])}>
                <Row>
                    <Col>
                        <Loading />
                    </Col>
                </Row>
            </Container>
        )
    }

    return <>{children}</>
}

export default LoadingPage
