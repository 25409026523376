import React, { type FC } from 'react'

import { useLocation } from 'react-router'

import { getDebugger } from '20-lib/debug'
import { useAppState } from '20-lib/hooks'

import { RedirectSearch } from '01-atoms/RedirectSearch/RedirectSearch'
import { RedirectSignupStep2 } from '01-atoms/RedirectSignupStep2/RedirectSignupStep2'
import { RedirectSignupStep3 } from '01-atoms/RedirectSignupStep3/RedirectSignupStep3'
import { RedirectWelcome } from '01-atoms/RedirectWelcome/RedirectWelcome'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Search as GatedComponent } from './Search'
import { type SearchProps } from './Search.types'

const debug = getDebugger('pages:Dating:Search:Search.gated.tsx')

export const Search: FC<SearchProps> = (props) => {
    const appContext = useAppState()

    const location = useLocation()

    debug.log('loading with', appContext)

    if (!appContext.synchronized) return <LoadingPage source='Search.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectWelcome />

    if (appContext.requireProfile) return <RedirectSignupStep2 />

    if (appContext.requireActivation) return <RedirectSignupStep3 />

    if (location.pathname !== '/find-my-cupid-match') return <RedirectSearch />

    return <GatedComponent {...props} />
}

export default Search
