import React, { type FC, useState, useEffect } from 'react'

import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useFormState } from 'react-hooks-use-form-state'

import { Constants } from '20-lib/constants'
import { getDebugger } from '20-lib/debug'
import { useAppState, useSearchService } from '20-lib/hooks'
import { RelationshipTypes, Gender } from '20-lib/constants/common'

import { Conditional } from '01-atoms/Conditional/Conditional'

import { Loading } from '02-molecules/Loading/Loading'

import { AgeSlider } from '03-particles/AgeSlider/AgeSlider'

import { ProfileCard } from '04-composites/ProfileCard/ProfileCard'

import { type SearchProps } from './Search.types'
import { type GenderInterest, type SearchingForRelationship } from '20-lib/hooks/search/types'

import './Search.css'

const debug = getDebugger('pages:Dating:Search:Search')

export const Search: FC<SearchProps> = () => {
    debug.log('constructor', 'called')

    const searchService = useSearchService()
    const appState = useAppState()

    const [_showFilterOptions, setShowFilterOptions] = useState(true)

    const [_searchRelationshipSearchingFor, setSearchRealtionshipSearchingFor] = useFormState(searchService.searchSearchingFor)
    const [_searchGenderInterest, setGenderInterest] = useFormState(searchService.searchInterest)
    const [_searchLowAge, setSearchLowAge] = useFormState(searchService.searchLowAge)
    const [_searchHighAge, setSearchHighAge] = useFormState(searchService.searchHighAge)

    // TODO: stop using force reset to update state from search
    const [forceReset, setForceReset] = useState(false)

    useEffect(() => {
        setSearchRealtionshipSearchingFor(searchService.searchSearchingFor)
        setGenderInterest(searchService.searchInterest)
        setSearchLowAge(searchService.searchLowAge)
        setSearchHighAge(searchService.searchHighAge)
    }, [
        searchService.searchInterest,
        searchService.searchSearchingFor,
        searchService.searchLowAge,
        searchService.searchHighAge,
        setSearchRealtionshipSearchingFor,
        setGenderInterest,
        setSearchLowAge,
        setSearchHighAge,
        forceReset
    ])

    const toggleFilterOptions = (): void => {
        setShowFilterOptions(!_showFilterOptions)
    }

    const updateSearch = (): void => {
        searchService.updateSearch({ lowAge: _searchLowAge, highAge: _searchHighAge, searchingFor: _searchRelationshipSearchingFor, interested: _searchGenderInterest })
    }

    const resetSearch = (): void => {
        searchService.resetSearch()
        setForceReset((prev) => !prev)
    }

    const showMore = (): void => {
        debug.log('showing more profiles')
        searchService.loadMore()
    }

    return (
        <div className={clsx('search-browse')}>
            <Helmet>
                <title>FilipinaLuv - Find Love</title>
            </Helmet>

            <Container>
                <div className={clsx('filter-view-bar')}>
                    <Row>
                        <Col md={6}>All Last Active Members</Col>
                        <Col md={6} className={clsx('text-right')}>
                            <Button variant='link'>
                                <FontAwesomeIcon
                                    icon={faSlidersH}
                                    onClick={() => {
                                        toggleFilterOptions()
                                    }}
                                    aria-hidden='true'
                                />
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Conditional condition={_showFilterOptions}>
                    <div className={clsx('bg')}>
                        <Row className={clsx('spacious')}>
                            <Col className={clsx('info-label')} xs={12} md={12} lg={2}>
                                <Form.Control
                                    name='searchInterest'
                                    as='select'
                                    type='text'
                                    value={_searchGenderInterest}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value)
                                        if (value in Gender) {
                                            setGenderInterest(value as GenderInterest)
                                        } else {
                                            console.error('Invalid search interest value:', value)
                                        }
                                    }}
                                >
                                    <Conditional condition={appState.userInfo.gender === Constants.InvertedGender.Guy || appState.userInfo.admin}>
                                        <option value={Constants.InvertedGender.Girl}>Girls</option>
                                        <option value={Constants.InvertedGender['LGBTQIA*+']}>LGBTQIA*+</option>
                                    </Conditional>
                                    <Conditional condition={appState.userInfo.gender !== Constants.InvertedGender.Guy || appState.userInfo.admin}>
                                        <option value={Constants.InvertedGender.Guy}>Guys</option>
                                    </Conditional>
                                </Form.Control>
                            </Col>

                            <Col className={clsx('info-label')} xs={12} md={12} lg={6}>
                                <AgeSlider low={_searchLowAge} high={_searchHighAge} onChangeLowAge={setSearchLowAge} onChangeHighAge={setSearchHighAge} />
                            </Col>

                            <Col className={clsx('info-label')} xs={12} md={12} lg={2}>
                                <Form.Control
                                    as='select'
                                    type='text'
                                    name='searchSearchingFor'
                                    value={_searchRelationshipSearchingFor}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value)
                                        if (value in RelationshipTypes) {
                                            setSearchRealtionshipSearchingFor(value as SearchingForRelationship)
                                        } else {
                                            console.error('Invalid search searching for value:', value)
                                        }
                                    }}
                                >
                                    {Object.entries(RelationshipTypes).map(([key, value]) => (
                                        <option key={key} value={key} className={clsx(`element-dating-${value.toLowerCase().replace(/ /g, '-')}`)}>
                                            {value}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>

                            <Col xs={12} md={12} lg={2}>
                                <span className={clsx('pull-right')}>
                                    <Button
                                        className={clsx('btn', 'btn-primary', 'search-btnn', 'btn-sm')}
                                        onClick={() => {
                                            updateSearch()
                                        }}
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        className={clsx('btn', 'btn-primary', 'search-btnn', 'btn-sm')}
                                        onClick={() => {
                                            resetSearch()
                                        }}
                                    >
                                        My Default
                                    </Button>
                                </span>
                            </Col>
                        </Row>
                    </div>
                </Conditional>

                <Container>
                    <div className={clsx('member-list')}>
                        <Conditional condition={searchService.loading}>
                            <Row style={{ clear: 'both' }}>
                                <Col>
                                    <Loading />
                                </Col>
                            </Row>
                        </Conditional>
                        <Conditional condition={!searchService.loading && searchService.searchProfiles.length > 0}>
                            <Row>
                                {searchService.searchProfiles.map((person) => (
                                    <ProfileCard key={person.profileId} person={person} />
                                ))}
                            </Row>
                        </Conditional>
                        <Conditional condition={!searchService.loading && searchService.searchProfiles.length === 0}>
                            <Row style={{ clear: 'both' }}>
                                <Col>No results...</Col>
                            </Row>
                        </Conditional>

                        <Conditional condition={!searchService.loading && searchService.searchProfiles.length > 0}>
                            <Row>
                                <div className={clsx('show-more-profile-btn')}>
                                    <Button
                                        variant='link'
                                        className={clsx('btn-yellow')}
                                        onClick={() => {
                                            showMore()
                                        }}
                                    >
                                        Show more profiles
                                    </Button>
                                </div>
                            </Row>
                        </Conditional>
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default Search
