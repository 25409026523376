import React from 'react'

import { createRoot } from 'react-dom/client'

import { ErrorBoundaryWrapper } from '01-atoms/ErrorBoundaryWrapper/ErrorBoundaryWrapper'

import { App } from '08-app/App/App'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { getRootContainer } from './util'

if (import.meta.env.MODE !== 'production') {
    import('10-styles/find-me.css')
}

const root = createRoot(getRootContainer())

root.render(
    <ErrorBoundaryWrapper name='root-app'>
        <App />
    </ErrorBoundaryWrapper>
)

serviceWorkerRegistration.unregister()
