import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type ChatsProps } from './Chats.types'

const LazyComponent = lazy(async () => await import('./Chats'))

export const Chats = (props: JSX.IntrinsicAttributes & ChatsProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Chats
