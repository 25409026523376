export const getRootContainer = (): HTMLElement => {
    let container = document.getElementById('app')

    if (container == null) {
        const bodyElement = document.getElementsByTagName('BODY')[0]
        container = document.createElement('div')
        container.id = 'app'
        bodyElement.appendChild(container)
    }

    return container
}
