import React, { type FC, useEffect, useState } from 'react'

import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Col, Container, Dropdown, Image, Row } from 'react-bootstrap'

import { assetFLRevampedLogoSmall } from '20-lib/ui'

import { RenderNavBarItem } from '02-molecules/RenderNavBarItem/RenderNavBarItem'
import { RenderNavBarMenuItem } from '02-molecules/RenderNavBarMenuItem/RenderNavBarMenuItem'

import { SmartLink } from '03-particles/SmartLink/SmartLink'

import { useScrollBlockerContext } from '09-providers/ScrollBlocker/ScrollBlocker.context'

import styles from './NavBar.module.scss'
import { type NavBarProps } from './NavBar.types'

export const NavBar: FC<NavBarProps> = ({ primaryMenuItems, dropdownMenuItems }) => {
    const { setScrollBlockerState } = useScrollBlockerContext()

    dropdownMenuItems = dropdownMenuItems ?? primaryMenuItems

    const [_showSideMenu, setShowSideMenu] = useState(false)

    let keyIdx = 0

    const getKey = (): number => keyIdx++

    const scale = primaryMenuItems.length < 5

    useEffect(() => {
        setScrollBlockerState(_showSideMenu)
    }, [_showSideMenu, setScrollBlockerState])

    useEffect(() => {
        return () => {
            setScrollBlockerState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={clsx(styles.NavBar, styles.DropShadow)}>
            <Container>
                <Row>
                    <Col className={clsx(styles.Menu, 'd-flex', 'align-items-center', 'justify-content-between')}>
                        <SmartLink to='/'>
                            <Image className={clsx(styles.Logo)} src={assetFLRevampedLogoSmall} />
                        </SmartLink>

                        {primaryMenuItems.map((e) => {
                            return <RenderNavBarItem item={e} key={getKey()} scale={scale} styles={styles} />
                        })}

                        <Dropdown onToggle={setShowSideMenu} className={clsx(styles.Toggle, 'd-block', `d-${scale ? 'md' : 'lg'}-none`)}>
                            <Dropdown.Toggle variant='outline-secondary' id='navbar-menu'>
                                <FontAwesomeIcon icon={faBars} className={clsx(styles.ToggleIcon)} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align='end'>
                                {dropdownMenuItems.map((e) => {
                                    return <RenderNavBarMenuItem item={e} key={getKey()} />
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NavBar
