import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectRoot } from '01-atoms/RedirectRoot/RedirectRoot'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { SignupStep1 as GatedComponent } from './SignupStep1.lazy'
import { type SignupStep1Props } from './SignupStep1.types'

export const SignupStep1: FC<SignupStep1Props> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='SignupStep1.gated.tsx' />

    if (appContext.isAuthenticated) return <RedirectRoot />

    return <GatedComponent {...props} />
}

export default SignupStep1
