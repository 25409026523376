import { mkEnum } from '20-lib/utils/enum'
import { type ConstantENUM, type GeoPosition } from '20-types/fl/misc'

const UnknownOpt = {
    [-1]: 'Unknown'
} as const

const DefaultOpt = {
    0: 'No Answer'
} as const

const NoYesOpt = {
    0: 'No',
    1: 'Yes'
} as const

export const GenderAnswers = {
    1: 'Girl',
    2: 'LGBTQIA*+',
    3: 'Guy'
} as const

// TODO: split up the constant
export const Gender = {
    ...UnknownOpt,
    ...DefaultOpt,
    ...GenderAnswers
} as const

export const InvertedGenderAnswers = {
    Girl: 1,
    'LGBTQIA*+': 2,
    Guy: 3
} as const

export const InvertedGender = {
    'Searching for': 0,
    ...InvertedGenderAnswers
} as const

export const RelationshipTypes = {
    0: 'Searching for',
    1: 'Marriage ONLY',
    2: 'Friendship',
    3: 'Casual Dating',
    4: 'BG/GF'
} as const

export const InvertedRelationshipTypes = {
    'Searching for': 0,
    'Marriage ONLY': 1,
    Friendship: 2,
    'Casual Dating': 3,
    'BG/GF': 4
} as const

export const CreditsChannels = {
    1: 'SIGNUP_BONUS',
    2: 'CAMS',
    3: 'BUNDLE',
    4: 'JOBS',
    5: 'NEW_MEMBER_MESSAGE_BONUS',
    6: 'EXPRESS_MESSAGE'
} as const

export const CreditsCosts: ConstantENUM = mkEnum({
    100: 'Express Message'
})

export const Credits = {
    Channels: CreditsChannels,
    Costs: CreditsCosts
}

export const BodyType = {
    ...DefaultOpt,
    1: 'Slim',
    2: 'Average',
    3: 'Little Chubby',
    4: 'Chubby'
} as const

export const EyesType = {
    ...DefaultOpt,
    1: 'Brown',
    2: 'Blue',
    3: 'Other'
} as const

export const HairType = {
    ...DefaultOpt,
    1: 'Black',
    2: 'Brown',
    3: 'Blonde',
    4: 'Other'
} as const

export const HasChildren = {
    ...DefaultOpt,
    ...NoYesOpt
} as const

export const WantsChildren = {
    ...DefaultOpt,
    ...NoYesOpt,
    3: 'Maybe'
} as const

export const RelationshipStatus = {
    ...DefaultOpt,
    1: 'Single',
    2: 'Married',
    3: 'Separated',
    4: 'Divorced',
    5: 'Annulled',
    6: 'Widowhood'
} as const

export const Smoking = {
    ...DefaultOpt,
    ...NoYesOpt
} as const

export const Drinking = {
    ...DefaultOpt,
    1: 'Non-Drinker',
    2: 'Light Drinker',
    3: 'Social Drinker',
    4: 'Heavy Drinker'
} as const

export const Tattoos = {
    ...DefaultOpt,
    ...NoYesOpt
} as const

export const Education = {
    ...DefaultOpt,
    1: 'Elementary School',
    2: 'Junior High School',
    3: 'Senior High School',
    4: 'College',
    5: 'University',
    6: 'Academic'
} as const

export const DropdownsHasChildren = {
    ...DefaultOpt,
    1: 'No, I do not have Children',
    2: 'Yes, I have Children'
} as const

export const DropdownsSmoking = {
    ...DefaultOpt,
    1: 'No, I do not Smoke',
    2: 'Yes, I do Smoke'
} as const

export const DropdownsTattoos = {
    ...DefaultOpt,
    1: 'No, I do not have Tattoos',
    2: 'Yes, I have Tattoos'
} as const

export const DropdownsWantsChildren = {
    ...DefaultOpt,
    1: 'No, I do not want Children',
    2: 'Yes, I would like to have children'
} as const

export const Dropdowns = {
    HasChildren: DropdownsHasChildren,
    Smoking: DropdownsSmoking,
    Tattoos: DropdownsTattoos,
    WantsChildren: DropdownsWantsChildren
}

export const DefaultSearchFilter = {
    interested: 0,
    searchingFor: 0,
    lowAge: 18 as number,
    highAge: 99 as number,
    searchInterest: 0,
    searchSearchingFor: 0,
    searchLowAge: 18,
    searchHighAge: 99
} as const

export const DefaultLatLng: GeoPosition = {
    lat: 0,
    lng: 0
}

export const DefaultRecordsPerPage = {
    DEFAULT: 24,
    LIST: 12,
    MESSAGES: 10,
    PROFILES: 12,
    SEARCH: 48,
    TABLE: 12
}

export const Defaults = {
    RecordsPerPage: DefaultRecordsPerPage,
    LatLng: DefaultLatLng,
    SearchFilter: DefaultSearchFilter
} as const

export const RequestsStatus = {
    [-1]: 'Rejected',
    0: 'Open',
    1: 'Approved'
} as const

export const Requests = {
    Status: RequestsStatus
}

export const Plans = {
    0: 'Unverified',
    1: 'Verified',
    2: 'Monthly Subscription',
    3: 'Quarterly Subscription',
    4: 'BiAnnual Subscription',
    5: 'Executive',
    6: 'Elite'
} as const

const UserVerificationStatus = {
    [-1]: 'Not Verified',
    0: 'Rejected',
    1: 'Approved'
} as const

export const UserVerification = {
    Status: UserVerificationStatus
}

export const NotificationsTranslations: Record<string, string> = {
    like: 'liked your profile',
    message: 'messaged you',
    profilelike: 'liked your profile',
    profilevisit: 'visited your profile',
    view: 'visited your profile'
}

export const Notifications = {
    Translations: NotificationsTranslations
}

export const MessageTypes: ConstantENUM = mkEnum({
    0: 'REGULAR',
    1: 'ACTION',
    2: 'EXPRESS',
    3: 'IMAGE',
    4: 'GIF'
})

export const Messages = {
    Types: MessageTypes
}

export const Constants = {
    Gender,
    InvertedGender,
    RelationshipTypes,
    InvertedRelationshipTypes,
    Credits,
    BodyType,
    EyesType,
    HairType,
    HasChildren,
    WantsChildren,
    RelationshipStatus,
    Smoking,
    Drinking,
    Tattoos,
    Education,
    Dropdowns,
    Defaults,
    Requests,
    Plans,
    UserVerification,
    Notifications,
    Messages
}

export default Constants
