import { type UserInfoModel } from '20-types/fl/database'

export const UserInfoTemplate: UserInfoModel = {
    userId: 0,
    admin: false,
    email: '',
    lastName: '',
    createdDate: '',
    profileCreatedDate: '',
    profileActive: false,
    birthdate: '',
    reviewed: false,
    verifiedEmail: false,
    verifiedEmailDate: '',
    planId: 0,
    planExpiry: '',
    planStatus: '',
    lowAge: 0,
    highAge: 0,
    pictureId: 0,
    phone: '',
    cellPhone: '',
    creditsTotal: 0,
    firstName: '',
    aboutUser: '',
    aboutMatch: '',
    hereTo: '',
    bodyType: 0,
    eyesType: 0,
    hairType: 0,
    hasChildren: 0,
    wantChildren: 0,
    smoking: 0,
    drinking: 0,
    tattoos: 0,
    currentStatus: 0,
    educationLevel: 0,
    inSchool: 0,
    lookingForGirl: false,
    lookingForGuy: false,
    lookingForGenderQueer: false,
    lastActive: '',
    activeTime: '',
    lastEgressVisit: '',
    lastIngressVisit: '',
    profileImages: [],
    sentMessageCount: 0,
    receivedMessageCount: 0,
    profileId: 0,
    profileName: '',
    userName: '',
    age: 0,
    gender: 0,
    profileImage: '',
    online: '',
    liked: false,
    className: '',
    kaPartner: false,
    city: '',
    location: '',
    countryId: -2,
    countryName: '',
    countryShortCode: '',
    lfSerious: false,
    lfRelationship: false,
    lfCasual: false,
    lfFriendship: false,
    hasProfileImage: false,
    youLastVisitProfile: '',
    userLastVisitProfile: ''
}

export default UserInfoTemplate
