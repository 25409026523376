import { type SWRResponse } from 'swr'

import { isEmpty } from '../validators'

export const blobToBase64Async = async (blob: Blob): Promise<string> => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)

    return await new Promise((resolve, reject) => {
        reader.onerror = () => {
            reject(reader.error)
        }
        reader.onloadend = () => {
            resolve((reader.result as string).split(',')[1])
        }
    })
}

// export const fnExists = (fnName: string): boolean => eval(`typeof ${fnName} === 'function'`) as boolean
// export const fnGetHandle = (fnName: string): (...args: unknown) => unknown => eval(`${fnName}`)
// export const varExists = (fnName: string): boolean => eval(`${fnName} != null`)

export const getFirstNonEmpty = <T extends string | number | object | null | undefined>(...args: T[]): T | null => {
    for (const arg of [...args]) {
        if (!isEmpty(arg)) return arg
    }

    return null
}

export const swrInFlight = (...hooks: SWRResponse[]): boolean => hooks.some((hook) => hook.isLoading || hook.data == null)
export const swrInLoadingState = (...hooks: SWRResponse[]): boolean => hooks.some((hook) => hook.isLoading || hook.isValidating || hook.data == null)
export const swrInErrorState = (hook: SWRResponse): boolean => hook.error != null && hook.error !== ''

export const coerceString = (value: unknown): string => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (typeof value === 'boolean') return value.toString()
    if (value == null) return ''
    if (typeof value === 'object') return JSON.stringify(value)

    throw new Error('Invalid string')
}

export const coerceNumber = (value: unknown): number => {
    if (typeof value === 'number') return value
    if (typeof value === 'string') return parseFloat(value)
    if (typeof value === 'boolean') return value ? 1 : 0
    if (value == null) return 0
    if (typeof value === 'object') return parseFloat(JSON.stringify(value))

    throw new Error('Invalid number')
}
