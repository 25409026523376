import React, { type FC, useEffect, useState } from 'react'

import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'
import { type Path, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

// const debug = getDebugger('particles:SmartLink:SmartLink')
import styles from './SmartLink.module.css'
import { type SmartLinkProps } from './SmartLink.types'
import { getIsActive, mkHref } from './SmartLink.utils'

export const SmartLink: FC<SmartLinkProps> = ({ to, children, type, bolder, className, ...props }) => {
    type = type ?? 'span'
    bolder = bolder ?? false

    const location = useLocation()
    const [_active, setActive] = useState(false)
    const [_href] = useState<Partial<Path>>(mkHref(to))

    useEffect(() => {
        const isActive = getIsActive(_href, location)

        setActive(isActive)
    }, [_href, location])

    if (type === 'dropdownitem')
        return (
            <Dropdown.Item
                as={Link}
                key={JSON.stringify(to)}
                to={_href}
                className={clsx(styles.SmartLink, styles.MenuItem, styles.Link, _active ? styles.Active : undefined, bolder ? styles.Bolder : undefined, className)}
                {...props}
            >
                {children}
            </Dropdown.Item>
        )

    return (
        <Link
            to={_href}
            key={JSON.stringify(to)}
            className={clsx(styles.SmartLink, styles.MenuItem, styles.Link, _active ? styles.Active : undefined, bolder ? styles.Bolder : undefined, className)}
            {...props}
        >
            {children}
        </Link>
    )
}

export default SmartLink
