import React, { type FC } from 'react'

import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Badge } from 'react-bootstrap'

import styles from './NotificationsMenuTitle.module.css'
import { type NotificationsMenuTitleProps } from './NotificationsMenuTitle.types'

export const NotificationsMenuTitle: FC<NotificationsMenuTitleProps> = ({ count, small, active }) => (
    <span className={clsx(styles.NotificationsMenuTitle, active ? styles.Active : '')}>
        <FontAwesomeIcon icon={faBell} aria-hidden='true' /> {small ?? false ? '' : 'Notifications'} {count > 0 && <Badge>{count}</Badge>}
    </span>
)

export default NotificationsMenuTitle
