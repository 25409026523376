import React, { type FC, useEffect, useMemo } from 'react'

import ReactGA4 from 'react-ga4'
import { type UaEventOptions } from 'react-ga4/types/ga4.d'

import { type FreeObject } from '20-types/utils'

import { useConfigContext } from '09-providers/Config/Config.context'

import { GTagServiceContext } from './GTagService.context'
import { type GTagServiceContextType, type GTagServiceProviderProps } from './GTagServiceProvider.types'

export const GTagServiceProvider: FC<GTagServiceProviderProps> = ({ children }) => {
    const { Config } = useConfigContext()

    useEffect(() => {
        ReactGA4.initialize(Config.misc.googleAnalyticsId)

        ReactGA4.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const gtagServiceContextValue = useMemo<GTagServiceContextType>(
        () => ({
            event(category: UaEventOptions['category'], action: UaEventOptions['action'], label: UaEventOptions['label'], value: UaEventOptions['value']): void {
                ReactGA4.event({ category, action, label, value })
            },

            pageview(path: unknown): void {
                ReactGA4.send({ hitType: 'pageview', page: path })
            },

            setUserId(userId: unknown): void {
                ReactGA4.set({ userId })
            },

            update(opts: FreeObject): void {
                ReactGA4.set({ ...opts })
            }
        }),
        []
    )

    return <GTagServiceContext.Provider value={gtagServiceContextValue}>{children}</GTagServiceContext.Provider>
}

export default GTagServiceProvider
