import { type Path, type To } from 'react-router'

const paths = ['/welcome']

export const getIsActive = (href: Path | Partial<Path>, location: Path): boolean => {
    if (paths.includes(location.pathname)) return href.hash === location.hash

    if (href.pathname != null && href.pathname.length > 1) return location.pathname.startsWith(href.pathname)

    return location.pathname === href.pathname
}

export const mkHref = (to: To): Partial<Path> => {
    if (typeof to === 'string') {
        const [pathname, hash] = to.split('#')

        return { pathname, hash }
    } else {
        return to
    }
}
