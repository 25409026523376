import React, { type FC, Fragment, useEffect, useState } from 'react'

import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'

import { SmartLink } from '03-particles/SmartLink/SmartLink'

import { useScrollBlockerContext } from '09-providers/ScrollBlocker/ScrollBlocker.context'

import styles from './UserMenu.module.scss'
import { type UserMenuProps } from './UserMenu.types'

export const UserMenu: FC<UserMenuProps> = ({ title, menuItems }) => {
    const { setScrollBlockerState } = useScrollBlockerContext()

    const [_showSideMenu, setShowSideMenu] = useState(false)
    let keyIdx = 0

    const getKey = (): number => keyIdx++

    useEffect(() => {
        setScrollBlockerState(_showSideMenu)
    }, [_showSideMenu, setScrollBlockerState])

    useEffect(() => {
        return () => {
            setScrollBlockerState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dropdown onToggle={setShowSideMenu} className={clsx(styles.UserMenu)}>
            <Dropdown.Toggle variant='outline-secondary' id='user-menu'>
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu align='end'>
                {menuItems.map((item) => {
                    if (item.type === 'link') {
                        const { onClick } = item

                        return (
                            <SmartLink key={getKey()} to={item.link} className={clsx(styles.MenuItem)} type='dropdownitem' onClick={onClick}>
                                {item.title}
                            </SmartLink>
                        )
                    }

                    if (item.type === 'divider') return <Dropdown.Divider key={getKey()} className={clsx(styles.MenuItem)} />

                    if (item.type === 'component') return <Fragment key={getKey()}>{item.children}</Fragment>

                    return null
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default UserMenu
