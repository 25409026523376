import { useMemo, useState } from 'react'

import { getRedirectTarget } from '20-lib/ui'
import { UserInfoTemplate } from '20-shared/templates'
import { type AppState } from '20-types/fl'
import { Constants, DefaultSearchFilter } from '20-lib/constants'
import { useAuth } from '..'
import { InvertedRelationshipTypes } from '20-lib/constants/common'

export const useAppState = (): AppState => {
    const [_geoLocalize] = useState<boolean>(false)

    const { userInfo, isAuthenticated, pollStatus } = useAuth()

    const appState = useMemo<AppState>(() => {
        return {
            isPublic: true,
            isAuthenticated,
            isAdmin: (userInfo ?? UserInfoTemplate).admin,
            userInfo: userInfo ?? UserInfoTemplate,
            geoLocalize: _geoLocalize,
            pollStatus,
            isNotAuthenticated: !isAuthenticated,
            isAuthenticatedAdmin: isAuthenticated && (userInfo ?? UserInfoTemplate).admin,
            isGuy: isAuthenticated && (userInfo ?? UserInfoTemplate).gender === 3,
            isKaPartner: isAuthenticated && (userInfo ?? UserInfoTemplate).kaPartner,
            isNotGuy: isAuthenticated && (userInfo ?? UserInfoTemplate).gender !== 3,
            shouldRedirect: isAuthenticated && pollStatus !== 'SUCCESS',
            requireProfile: (pollStatus != null && typeof pollStatus === 'string' && pollStatus === 'INCOMPLETEPROFILE') ?? false,
            requireActivation: (pollStatus != null && typeof pollStatus === 'string' && pollStatus === 'INACTIVEPROFILE') ?? false,
            hasProfileImage: Boolean((userInfo ?? UserInfoTemplate).hasProfileImage),
            isActive: isAuthenticated && (userInfo ?? UserInfoTemplate).profileActive,
            forceRedirect: isAuthenticated && pollStatus !== 'SUCCESS',
            redirectTarget: getRedirectTarget(pollStatus),
            verifiedEmail: (userInfo ?? UserInfoTemplate).verifiedEmail,
            synchronized: true,
            override: false,
            // convoluted logic to determine what the user is looking for and their interest
            interest: userInfo?.lookingForGirl
                ? Constants.InvertedGender.Girl
                : userInfo?.lookingForGuy
                  ? Constants.InvertedGender.Guy
                  : userInfo?.lookingForGenderQueer
                    ? Constants.InvertedGender['LGBTQIA*+']
                    : Constants.Defaults.SearchFilter.searchingFor,
            searchingFor: userInfo?.lfSerious
                ? InvertedRelationshipTypes['Marriage ONLY']
                : userInfo?.lfRelationship
                  ? InvertedRelationshipTypes['BG/GF']
                  : userInfo?.lfCasual
                    ? InvertedRelationshipTypes['Casual Dating']
                    : userInfo?.lfFriendship
                      ? InvertedRelationshipTypes['Friendship']
                      : DefaultSearchFilter.interested
        }
    }, [isAuthenticated, userInfo, _geoLocalize, pollStatus])

    return appState
}
