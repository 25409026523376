import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type CookiesProps } from './Cookies.types'

const LazyComponent = lazy(async () => await import('./Cookies'))

export const Cookies = (props: JSX.IntrinsicAttributes & CookiesProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Cookies
