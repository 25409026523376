import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SignupStep1Props } from './SignupStep1.types'

const LazyComponent = lazy(async () => await import('./SignupStep1'))

export const SignupStep1 = (props: JSX.IntrinsicAttributes & SignupStep1Props): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default SignupStep1
