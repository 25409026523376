import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectLogin } from '01-atoms/RedirectLogin/RedirectLogin'
import { RedirectSignupStep2 } from '01-atoms/RedirectSignupStep2/RedirectSignupStep2'
import { RedirectSignupStep3 } from '01-atoms/RedirectSignupStep3/RedirectSignupStep3'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Credits as GatedComponent } from './Credits.lazy'
import { type CreditsProps } from './Credits.types'

export const Credits: FC<CreditsProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Credits.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectLogin />

    if (appContext.requireProfile) return <RedirectSignupStep2 />

    if (appContext.requireActivation) return <RedirectSignupStep3 />

    return <GatedComponent {...props} />
}

export default Credits
