import React, { lazy, Suspense, type SuspenseProps } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type BuyCreditsProps } from './BuyCredits.types'

const LazyComponent = lazy(async () => await import('./BuyCredits'))

export const BuyCredits = (props: JSX.IntrinsicAttributes & BuyCreditsProps & SuspenseProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default BuyCredits
