import React, { type FC } from 'react'

import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'

import { SmartLink } from '03-particles/SmartLink/SmartLink'

import styles from './RenderNavBarMenuItem.module.scss'
import { type RenderNavBarMenuItemProps } from './RenderNavBarMenuItem.types'

export const RenderNavBarMenuItem: FC<RenderNavBarMenuItemProps> = ({ key, item }) => {
    if (item.type === 'link') {
        const { onClick } = item

        return (
            <SmartLink key={key} to={item.link} className={clsx(styles.MenuItem)} type='dropdownitem' onClick={onClick}>
                {item.title}
            </SmartLink>
        )
    }

    if (item.type === 'divider') return <Dropdown.Divider className={clsx(styles.MenuItem)} />

    if (item.type === 'component') return <>{item.children}</>

    return null
}

export default RenderNavBarMenuItem
