import { useMemo } from 'react'

import { type CompileSearchRequestParams, type SearchParams } from './types'

export const compileSearchRequestParams = (stateObj: CompileSearchRequestParams): SearchParams => {
    const { page, lowAge, highAge, searchingFor, interested, countryName } = stateObj

    let searchRequestParams = {
        page: page ?? 1,
        recordsPerPage: 48,
        countryName
    }

    if (lowAge > -1) {
        searchRequestParams = { ...searchRequestParams, ...{ lowAge } }
    }
    if (highAge > -1) {
        searchRequestParams = { ...searchRequestParams, ...{ highAge } }
    }
    if (searchingFor > -1) {
        searchRequestParams = { ...searchRequestParams, ...{ searchingFor } }
    }
    if (interested > -1) {
        searchRequestParams = { ...searchRequestParams, ...{ interested } }
    }

    return searchRequestParams
}

export const updateKeys = ['lowAge', 'highAge', 'searchingFor', 'interested']

export const useSearchRequestParams = (stateObj: CompileSearchRequestParams): SearchParams => {
    const { page, lowAge, highAge, searchingFor, interested, countryName } = stateObj

    return useMemo(() => {
        let searchRequestParams = {
            page: page ?? 1,
            recordsPerPage: 48,
            countryName
        }

        if (lowAge > -1) {
            searchRequestParams = { ...searchRequestParams, ...{ lowAge } }
        }
        if (highAge > -1) {
            searchRequestParams = { ...searchRequestParams, ...{ highAge } }
        }
        if (searchingFor > -1) {
            searchRequestParams = { ...searchRequestParams, ...{ searchingFor } }
        }
        if (interested > -1) {
            searchRequestParams = { ...searchRequestParams, ...{ interested } }
        }

        return searchRequestParams
    }, [countryName, highAge, interested, lowAge, page, searchingFor])
}
