import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type VisitsProps } from './Visits.types'

const LazyComponent = lazy(async () => await import('./Visits'))

export const Visits = (props: JSX.IntrinsicAttributes & VisitsProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Visits
