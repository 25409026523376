import { type Cache } from 'swr'

export function localStorageProvider(): Cache {
    const map = new Map<string, unknown>(JSON.parse(localStorage.getItem('app-cache') ?? '[]') as Array<[string, unknown]>)

    window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()))
        localStorage.setItem('app-cache', appCache)
    })

    return map as unknown as Cache
}
