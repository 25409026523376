import { unserialize } from 'php-serialize'

import { getDebugger } from '../debug'

const debug = getDebugger('lib:utils:parsers')

export const parseRequestData = <T>(data: string): T | null => {
    const fnDebug = debug.extend('parseRequestData')

    let parsedData: T | null = null

    try {
        parsedData = JSON.parse(data) as T
    } catch (jsonError) {
        fnDebug.log('Failed to parse JSON data. Trying PHP...')
        try {
            parsedData = unserialize(Buffer.from(data, 'base64').toString('ascii')) as T
        } catch (phpError) {
            fnDebug.log('Failed to unserialize PHP data')
        }
    }

    return parsedData
}

export const parseRequestDocuments = (documents?: Record<string, unknown>): Record<string, string> => {
    documents = documents ?? {}

    const documentsResult: Record<string, string> = {}

    for (const [docKey, docUrl] of Object.entries(documents)) {
        const objectKey = (docUrl as string)
            .substring(0, (docUrl as string).indexOf('?'))
            .split('/')
            .slice(4)
            .join('/')

        documentsResult[docKey] = objectKey
    }

    return documentsResult
}
