import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SignupStep3Props } from './SignupStep3.types'

const LazyComponent = lazy(async () => await import('./SignupStep3'))

export const SignupStep3 = (props: JSX.IntrinsicAttributes & SignupStep3Props): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default SignupStep3
