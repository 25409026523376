import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SocialShareProps } from './SocialShare.types'

const LazyComponent = lazy(async () => await import('./SocialShare'))

export const SocialShare = (props: JSX.IntrinsicAttributes & SocialShareProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default SocialShare
