import { useBackendURL } from './common'

export const useVerifyUploadURL = (): string => {
    return useBackendURL('users', 'verifyupload')
}

export const useGetUploadURL = (): string => {
    return useBackendURL('users', 'verifyupload')
}

export const useSaveRequestURL = (): string => {
    return useBackendURL('users', 'saverequest')
}

export const useUserInfoURL = (): string => {
    return useBackendURL('users', 'me')
}

export const useVerifyOTPURL = (): string => {
    return useBackendURL('users', 'verifyotp')
}

export const useResendOTPURL = (): string => {
    return useBackendURL('users', 'resendotp')
}

export const useForgotPasswordURL = (): string => {
    return useBackendURL('users', 'forgotpassword')
}

export const useVerifyTokenURL = (): string => {
    return useBackendURL('users', 'verify-token')
}

export const useGetInviteLink = (): string => {
    return useBackendURL('users', 'getinvitelink')
}

export const useChangePasswordURL = (): string => {
    return useBackendURL('users', 'changepassword')
}

export const useResetPasswordURL = (): string => {
    return useBackendURL('users', 'reset-password')
}

export const useGetBlockedProfilesURL = (): string => {
    return useBackendURL('users', 'getblockeduserprofiles')
}

export const useRemoveBlockedProfileURL = (): string => {
    return useBackendURL('users', 'removeblockeduserprofile')
}

export const useRequestVerifyEmailURL = (): string => {
    return useBackendURL('users', 'request-verify-email')
}

export const useVerifyEmailURL = (): string => {
    return useBackendURL('users', 'verify-email')
}

export const useSignUpStep1URL = (): string => {
    return useBackendURL('users', 'sign-up')
}

export const useSignUpStep2URL = (): string => {
    return useBackendURL('users', 'sign-up-step-2')
}

export const useUpdateProfileURL = (): string => {
    return useBackendURL('users', 'updateprofileinfo')
}

export const useLoginURL = (): string => {
    return useBackendURL('users', 'loginuser')
}
