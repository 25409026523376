import { useBackendURL, useBackendURLWithParams } from './common'

export const useLikeProfileURL = (): string => {
    return useBackendURL('profiles', 'likeprofile')
}

export const useBlockProfileURL = (): string => {
    return useBackendURL('profiles', 'blockuserprofile')
}

export const useReportProfileURL = (): string => {
    return useBackendURL('profiles', 'reportuserprofile')
}

export const useVisitProfileURL = (): string => {
    return useBackendURL('profiles', 'visitprofile')
}

export const useGetProfileNoteURL = (profileId: number): string => {
    return useBackendURLWithParams('profiles', 'getnote', { profileId })
}

export const useSaveProfileNoteURL = (): string => {
    return useBackendURL('profiles', 'savenote')
}

export const useGetProfileInfoURL = (profileId?: number): string => {
    return useBackendURLWithParams('profiles', 'getprofile', { profileId })
}

export const useExecutiveRequestURL = (): string => {
    return useBackendURL('profiles', 'executive')
}

export const useSetProfileImageURL = (): string => {
    return useBackendURL('profiles', 'setprofileimage')
}

export const useUploadProfileImageURL = (): string => {
    return useBackendURL('profiles', 'uploadprofileimage')
}

export const useRemoveProfilePictureURL = (): string => {
    return useBackendURL('profiles', 'removeprofileimage')
}
