import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type ExecutiveProps } from './Executive.types'

const LazyComponent = lazy(async () => await import('./Executive'))

export const Executive = (props: JSX.IntrinsicAttributes & ExecutiveProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Executive
