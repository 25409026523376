import React, { type FC, useEffect } from 'react'

import 'react-toastify/dist/ReactToastify.css'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

import { stateMachine } from 'pretty-state-machine'
import { RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'

import { fetcher } from '20-lib/api'
import { localStorageProvider } from '20-lib/api/swr'
import { getDebugger } from '20-lib/debug'

import { ProviderComposer } from '01-atoms/ProviderComposer/ProviderComposer'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { ConfigProvider } from '09-providers/Config/ConfigProvider'
import { GTagServiceProvider } from '09-providers/GTagService/GTagServiceProvider'
import { ScrollBlockerProvider } from '09-providers/ScrollBlocker/ScrollBlockerProvider'

import { AppRouter } from './App.router'

import './styles/App.scss'

const debug = getDebugger('app:App')

const providers = [ScrollBlockerProvider, ConfigProvider, GTagServiceProvider]

export const App: FC = () => {
    useEffect(() => {
        const psmLogger = (msg: unknown): void => {
            debug.extend('psm').log(msg)
        }

        stateMachine.sub(psmLogger)

        return () => {
            stateMachine.unsub(psmLogger)
        }
    }, [])

    return (
        <SWRConfig
            value={{
                fetcher,
                onError: (err) => {
                    console.error(err)
                },
                provider: localStorageProvider,
                revalidateOnFocus: false,
                revalidateOnMount: true,
                revalidateOnReconnect: true,
                revalidateIfStale: true
            }}
        >
            <ProviderComposer providers={providers}>
                <RouterProvider router={AppRouter} fallbackElement={<LoadingPage source='App.tsx' />} />
            </ProviderComposer>
        </SWRConfig>
    )
}

export default App
