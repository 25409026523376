import stateMachine from 'pretty-state-machine'
import { type NavigateFunction } from 'react-router'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import { getter, postWithParams } from '20-lib/api'
import { getDebugger } from '20-lib/debug'
import { usePSM } from '20-lib/external/psm'
import { useLoginURL, useUserInfoURL } from '20-lib/hooks/'
import { useSocialLoginURL } from '20-lib/hooks/api/urls/social'
import { swrInErrorState, swrInLoadingState } from '20-lib/utils'
import { type APISocialLoginResult, type APIUserInfoResult, type APIUserLoginResult } from '20-types/fl'

import { type UseAuthHook } from './types'

const debug = getDebugger('lib:hooks:auth')

export const useAuth = (): UseAuthHook => {
    const [isAuthenticated, setIsAuthenticated] = usePSM<boolean>('isAuthenticated', false)

    const userLoginURL = useLoginURL()
    const userInfoURL = useUserInfoURL()
    const socialLoginURL = useSocialLoginURL()

    const userInfoResult = useSWR<APIUserInfoResult>(isAuthenticated ? userInfoURL.toString() : null, { refreshInterval: 15000 })

    const clearAuthenticationToken = (): void => {
        if (window.flAuthToken != null) window.flAuthToken = ''
        if (localStorage.flAuthToken != null) window.flAuthToken = ''
    }

    const setAuthenticationToken = (flAuthToken: string, rememberme?: boolean): void => {
        rememberme = rememberme ?? false

        window.flAuthToken = flAuthToken

        if (rememberme) {
            debug.log('setAuthenticationToken', 'remembering token')
            localStorage.setItem('flAuthToken', flAuthToken)
        }
    }

    const logout = (): void => {
        clearAuthenticationToken()

        setIsAuthenticated(false)

        stateMachine.pub({ isAuthenticated: false, geoLocalize: false, userInfo: {}, searchProfileIds: [], searchProfiles: [], userGender: null })

        localStorage.clear()
    }

    const login = async (email: string, password: string, rememberme: boolean | undefined, navigate: NavigateFunction): Promise<void> => {
        debug.extend('login').log('Logging in...')

        const loginResult = await postWithParams<APIUserLoginResult>(userLoginURL, { email: email.trim(), password })

        if (loginResult.result === 'ERROR') {
            toast.error(loginResult.error ?? loginResult.message ?? 'An unknown error occurred while logging in. Please try again later!')
        } else if (loginResult.authToken == null) {
            console.warn('Missing authToken')
            toast.error('An unexpected problem occurred while logging in. Please try again later!')
        } else {
            debug.extend('login').log('Success! Fetching user details')

            setAuthenticationToken(loginResult.authToken, rememberme)

            const userResult = await getter<APIUserInfoResult>(userInfoURL)

            if (userResult.result === 'OK') {
                toast.success('You have successfully logged in..')

                setIsAuthenticated(true)

                debug.extend('login').log(`Success! Redirecting with ${loginResult.code}`)

                setTimeout(() => {
                    if (loginResult.code === 'INCOMPLETEPROFILE') {
                        navigate('/sign-up-create-unique-profile')
                    } else if (loginResult.code === 'INACTIVEPROFILE') {
                        navigate('/sign-up-done')
                    } else {
                        navigate('/find-my-cupid-match')
                    }
                }, 1500)
            }
        }
    }

    const socialLogin = async (provider: string | undefined, user: string | undefined, rememberme: boolean | undefined, navigate: NavigateFunction): Promise<void> => {
        const result = await postWithParams<APISocialLoginResult>(socialLoginURL, { provider, user })

        if (result.result === 'ERROR') {
            toast.error(result.error ?? result.message ?? 'An unknown error occurred while logging in. Please try again later!')
        } else if (result.code === 'DOSOCIALSIGNUP') {
            navigate('/sign-up', {
                state: {
                    prefilled: true,
                    user
                }
            })
        } else if (result.authToken == null) {
            console.warn('Missing authToken')
            toast.error('An unexpected problem occurred while logging in. Please try again later!')
        } else {
            setAuthenticationToken(result.authToken, rememberme)

            const userResult = await getter<APIUserInfoResult>(userInfoURL)

            if (userResult.result === 'OK') {
                toast.success('You have successfully logged in..')

                setIsAuthenticated(true)

                debug.extend('socialLogin').log(`Success! Redirecting with ${result.code}`)

                setTimeout(() => {
                    if (result.code === 'INCOMPLETEPROFILE') {
                        navigate('/sign-up-create-unique-profile')
                    } else if (result.code === 'INACTIVEPROFILE') {
                        navigate('/sign-up-done')
                    } else {
                        navigate('/find-my-cupid-match')
                    }
                }, 1500)
            }
        }
    }

    return {
        login,
        logout,
        socialLogin,
        clearAuthenticationToken,
        setAuthenticationToken,
        isAuthenticated,
        userInfo: userInfoResult.data?.userInfo,
        isLoading: userInfoResult.isLoading,
        userInfoMutate: userInfoResult.mutate,
        userInfoInFlight: swrInLoadingState(userInfoResult),
        userInfoInError: swrInErrorState(userInfoResult),
        pollStatus: userInfoResult.data?.code ?? 'LOADING'
    }
}
