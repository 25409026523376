import React, { type FC } from 'react'

import { Img } from 'react-image'

import { Loading } from '02-molecules/Loading/Loading'
import { VisibilitySensor } from '02-molecules/VisibilitySensor/VisibilitySensor'

import { type LazyImageProps } from './LazyImage.types'

export const LazyImage: FC<LazyImageProps> = ({ src, className, style, onClick }) => {
    return (
        <VisibilitySensor>
            <Img src={src} className={className} loader={<Loading className={className} />} onClick={onClick} style={style} />
        </VisibilitySensor>
    )
}

export default LazyImage
