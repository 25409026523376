import React, { lazy, Suspense, type SuspenseProps } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type CreditsPayoutProps } from './CreditsPayout.types'

const LazyComponent = lazy(async () => await import('./CreditsPayout'))

export const CreditsPayout = (props: JSX.IntrinsicAttributes & CreditsPayoutProps & SuspenseProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default CreditsPayout
