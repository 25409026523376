import React, { type FC } from 'react'

// const debug = getDebugger('pages:Public:Landing:Landing.gated')
import { useAppState } from '20-lib/hooks'

import { RedirectRoot } from '01-atoms/RedirectRoot/RedirectRoot'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Landing as GatedComponent } from './Landing.lazy'
import { type LandingProps } from './Landing.types'

export const Landing: FC<LandingProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Landing.gated.tsx' />

    if (appContext.isAuthenticated) return <RedirectRoot />

    return <GatedComponent {...props} />
}

export default Landing
