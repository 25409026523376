import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type PrivacyPolicyProps } from './PrivacyPolicy.types'

const LazyComponent = lazy(async () => await import('./PrivacyPolicy'))

export const PrivacyPolicy = (props: JSX.IntrinsicAttributes & PrivacyPolicyProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default PrivacyPolicy
