import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SettingsProps } from './Settings.types'

const LazyComponent = lazy(async () => await import('./Settings'))

export const Settings = (props: JSX.IntrinsicAttributes & SettingsProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Settings
