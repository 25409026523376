import { coerceString } from '20-lib/utils'
import { type APIRequestParams } from '20-types/fl'

export const coerceURL = (urlInput: unknown): URL => {
    if (urlInput == null) throw new Error('Null URL')

    if (urlInput instanceof URL) return urlInput

    if (typeof urlInput === 'string') return new URL(urlInput)

    if (typeof urlInput === 'object' && 'url' in urlInput && urlInput.url != null && (urlInput.url instanceof URL || typeof urlInput.url === 'string')) return new URL(urlInput.url)

    throw new Error('Invalid URL')
}

export const coerceBackendUrl = (apiUrl: string, ...uriParts: string[]): string => {
    if (apiUrl == null) throw new Error('apiUrl is undefined')
    if (apiUrl.length === 0) throw new Error('apiUrl is empty')
    if (!apiUrl.startsWith('https')) throw new Error('apiUrl is not a valid url')
    if (uriParts == null || uriParts.length === 0) throw new Error('uriParts is empty')

    return new URL(uriParts[0].startsWith(apiUrl) ? uriParts.join('/') : [apiUrl, ...uriParts].join('/')).toString()
}

export const coerceBackendUrlWithParams = (urlInput: RequestInfo | URL, params?: APIRequestParams): string => {
    const url = coerceURL(urlInput)

    if (params != null) {
        for (const key in params) {
            if (params[key] != null) url.searchParams.set(key, coerceString(params[key]))
        }
    }

    return url.toString()
}

export const compileBackendURLWithParams = (uriParts: string[], params?: APIRequestParams): string => {
    const url = new URL(uriParts.join('/'))

    if (params != null) {
        for (const key in params) {
            if (params[key] != null) url.searchParams.set(key, coerceString(params[key]))
        }
    }

    return url.toString()
}
