import { useMemo } from 'react'

import { coerceBackendUrl } from '20-lib/api'
import { coerceString } from '20-lib/utils'
import { type APIRequestParams } from '20-types/fl'

import { useConfigContext } from '09-providers/Config/Config.context'

export const useBackendURL = (serviceType: string, serviceEndpoint: string): string => {
    const { Config } = useConfigContext()

    return useMemo(() => coerceBackendUrl(Config.api.apiUrl, serviceType, serviceEndpoint), [Config.api.apiUrl, serviceEndpoint, serviceType])
}

export const useBackendURLWithParams = (serviceType: string, serviceEndpoint: string, params: APIRequestParams): string => {
    const { Config } = useConfigContext()

    return useMemo(() => {
        const url = new URL([Config.api.apiUrl, serviceType, serviceEndpoint].join('/'))

        if (params != null) {
            for (const key in params) {
                if (params[key] != null) url.searchParams.set(key, coerceString(params[key]))
            }
        }

        return url.toString()
    }, [Config.api.apiUrl, params, serviceEndpoint, serviceType])
}
