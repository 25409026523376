import { type APINewNotificationsRequest, type APIRequestParams } from '20-types/fl'

import { useBackendURL, useBackendURLWithParams } from './common'

export const useGetNotificationsURL = (params: APIRequestParams): string => {
    return useBackendURLWithParams('notifications', 'getnotifications', params)
}

export const useNewNotificationsURL = (params: APINewNotificationsRequest): [string, string] => {
    return [useBackendURL('notifications', 'getnewnotifications'), useBackendURLWithParams('notifications', 'getnewnotifications', params)]
}

export const useMarkNotificationsReadURL = (): string => {
    return useBackendURL('notifications', 'markread')
}

export const useRemoveNotificationsURL = (): string => {
    return useBackendURL('notifications', 'remove')
}

export const useNotificationsPollURL = (params: APIRequestParams): [string, string] => {
    return [useBackendURL('notifications', 'poll'), useBackendURLWithParams('notifications', 'poll', params)]
}
