import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type KaPartnerProps } from './KaPartner.types'

const LazyComponent = lazy(async () => await import('./KaPartner'))

export const KaPartner = (props: JSX.IntrinsicAttributes & KaPartnerProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default KaPartner
