import { createContext, useContext } from 'react'

import { type ScrollBlockerContextType } from './ScrollBlockerProvider.types'

export const defaultScrollBlockerContext: ScrollBlockerContextType = {
    setScrollBlockerState: () => {
        throw new Error('Function not implemented')
    }
}

export const ScrollBlockerContext = createContext<ScrollBlockerContextType | undefined>(undefined)

export const useScrollBlockerContext = (): ScrollBlockerContextType => {
    const scrollBlockerContext = useContext<ScrollBlockerContextType | undefined>(ScrollBlockerContext)

    if (scrollBlockerContext === undefined) {
        throw new Error('useScrollBlockerContext must be used within a ScrollBlockerProvider')
    }

    return scrollBlockerContext
}
