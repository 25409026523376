import { createContext, useContext } from 'react'

import { type GTagServiceContextType } from './GTagServiceProvider.types'

export const GTagServiceContext = createContext<GTagServiceContextType | undefined>(undefined)

export const useGTAGService = (): GTagServiceContextType => {
    const context = useContext(GTagServiceContext)

    if (context === undefined) {
        throw new Error('useGTAGService must be used within a GTagServiceProvider')
    }

    return context
}
