import { type AdminUserInfoModel } from '20-types/fl/database'

import UserInfoTemplate from './UserInfoTemplate'

export const AdminUserInfoTemplate: AdminUserInfoModel = Object.assign(UserInfoTemplate, {
    active: false,
    banned: false
})

export default AdminUserInfoTemplate
