import React, { type FC } from 'react'

import clsx from 'clsx'

import { SmartLink } from '03-particles/SmartLink/SmartLink'

import styles from './RenderNavBarItem.module.scss'
import { type RenderNavBarItemProps } from './RenderNavBarItem.types'

export const RenderNavBarItem: FC<RenderNavBarItemProps> = ({ key, item, scale }) => {
    if (item.type === 'link') {
        const { onClick } = item

        return (
            <SmartLink key={key} to={item.link} className={clsx(styles.MenuItem, 'd-none', `d-${scale ? 'md' : 'lg'}-block`)} type='span' onClick={onClick}>
                {item.title}
            </SmartLink>
        )
    }

    if (item.type === 'divider')
        return (
            <span key={key} className={clsx(styles.MenuItem, 'd-none', `d-${scale ? 'md' : 'lg'}-block`)}>
                &nbsp;
            </span>
        )

    if (item.type === 'component')
        return (
            <span key={key} className={clsx(styles.MenuItem, 'd-none', `d-${scale ? 'md' : 'lg'}-block`)}>
                {item.children}
            </span>
        )

    return null
}

export default RenderNavBarItem
