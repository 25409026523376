import React, { type FC } from 'react'

import { Navigate } from 'react-router'

import { toast } from '20-lib/toast'

import { type RedirectLoginProps } from './RedirectLogin.types'

export const RedirectLogin: FC<RedirectLoginProps> = () => {
    toast.error('You must be logged in to view this page. Please login!')

    return <Navigate to='/login' replace />
}

export default RedirectLogin
