import React, { lazy, Suspense, type SuspenseProps } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type PlansProps } from './Plans.types'

const LazyComponent = lazy(async () => await import('./Plans'))

export const Plans = (props: JSX.IntrinsicAttributes & PlansProps & SuspenseProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Plans
