export const assetProfileOther = '/static/images/profile_other.png'

export const assetBlueCheckMark = '/static/images/checkmark-blue-xs.png'

export const profileImageFallback = '/static/images/pic11.png'

export const assetFLRevampedLogoSmall = '/static/images/FL-Logo-Revamp-2023-07-27-Small.png'

export const assetFLSlide = '/static/images/filipinaluv_slide.png'

export const assetShim = '/static/images/shim.png'

export const assetOtherPic1 = '/static/images/other-pic-1.jpg'

export const assetLogoNew = '/static/images/logo-new.png'

export const assetGiftboxIcon = '/static/images/giftbox-icon.png'

export const assetSectionGroups = '/static/images/section-groups.png'

export const assetBagOfRice = '/static/images/bagofrice.jpg'

export const assetEducation = '/static/images/education.jpg'

export const assetEditPictogram = '/static/images/edit.png'

export const assetRedMagnifierGlasWithHeart = '/static/images/red-magnifier-with-a-heart.png'

export const assetFLQueerHeartBW = '/static/images/FilipinaLuv_LGBTQ_heart-bw.png'

export const assetFLQueerHeart = '/static/images/FilipinaLuv_LGBTQ_heart.png'

export const assetFLHeartBW = '/static/images/FilipinaLuv_heart-bw.png'

export const assetFLHeart = '/static/images/FilipinaLuv_heart.png'

export const assetPoseWomen = '/static/images/avatar1.webp'

export const assetBGGF = '/static/images/bfgf.png'

export const assetBooks = '/static/images/books.jpg'

export const assetCalendarIcon = '/static/images/calendar-icon.png'

export const assetCasualRelationshipIcon = '/static/images/casual.png'

export const assetCoupleInLoveParis = '/static/images/couple-youth-in-love-paris.png'

export const assetFLLogoIcon = '/static/images/filipinaluv-logo-icon.png'

export const assetFriendshipIcon = '/static/images/friendship.png'

export const assetHeartBeatAnim = '/static/images/heartbeat.gif'

export const assetLogooNew = '/static/images/logoonew.png'

export const assetLoyaltyIcon = '/static/images/loyalty-icon.png'

export const assetPoseMen = '/static/images/mens.jpg'

export const assetUsersIcon = '/static/images/users.png'

export const assetMarriageIcon = '/static/images/wedding-rings-icon.png'
