import React, { type FC, useEffect, useState } from 'react'

import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { useNotifications } from '20-lib/hooks/notifications'

import { SmartLink } from '03-particles/SmartLink/SmartLink'
import { getIsActive, mkHref } from '03-particles/SmartLink/SmartLink.utils'

import { NotificationItem } from '04-composites/NotificationItem/NotificationItem'

import { useScrollBlockerContext } from '09-providers/ScrollBlocker/ScrollBlocker.context'

import styles from './NotificationsMenu.module.scss'
import { type NotificationsMenuProps } from './NotificationsMenu.types'
import { NotificationsMenuTitle } from './NotificationsMenuTitle/NotificationsMenuTitle'

export const NotificationsMenu: FC<NotificationsMenuProps> = ({ small }) => {
    const { setScrollBlockerState } = useScrollBlockerContext()

    small = small ?? false

    const [_showSideMenu, setShowSideMenu] = useState(false)

    const { notifications, removeNotification } = useNotifications()

    const location = useLocation()

    const [_location, updateLocation] = useState(location)

    const [_active, setActive] = useState(false)
    const href = mkHref('/notifications')

    useEffect(() => {
        if (_location !== location) {
            updateLocation(location)
        }
    }, [_location, location])

    useEffect(() => {
        const isActive = getIsActive(href, location)

        setActive(isActive)
    }, [href, location])

    useEffect(() => {
        setScrollBlockerState(_showSideMenu)
    }, [_showSideMenu, setScrollBlockerState])

    useEffect(() => {
        return () => {
            setScrollBlockerState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={clsx(styles.NotificationsMenu)}>
            <Dropdown onToggle={setShowSideMenu}>
                <Dropdown.Toggle variant='secondary-outline' id='dropdown-notifications'>
                    <NotificationsMenuTitle count={notifications.length} small={small} active={_active} />
                </Dropdown.Toggle>

                <Dropdown.Menu align='end'>
                    {/* <Dropdown.Item href='#/action-1'>Action</Dropdown.Item> */}
                    {notifications.length > 0
                        ? notifications.map((notification) => {
                              return (
                                  <SmartLink key={notification.uuid} to={`/profile/${notification.profileId}`} type='dropdownitem'>
                                      <NotificationItem
                                          notification={notification}
                                          removeNotification={() => {
                                              removeNotification(notification)
                                          }}
                                      />
                                  </SmartLink>
                              )
                          })
                        : "Sorry, you don't have any new notifications!"}
                    <SmartLink to='/notifications' type='dropdownitem' className={clsx('centered')} bolder>
                        More notifications
                    </SmartLink>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default NotificationsMenu
