import React, { type FC, useState } from 'react'

import { faEnvelope, faEnvelopeOpen, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import TimeAgo from 'react-timeago'

import { postWithParams } from '20-lib/api'
import { Constants } from '20-lib/constants'
import { getDebugger } from '20-lib/debug'
import { useMarkNotificationsReadURL } from '20-lib/hooks/api/urls'
import { profileImageFallback } from '20-lib/ui'
import { type NotificationData } from '20-types/fl'
import { type OnChangeMouseEvent } from '20-types/fl/ui'

import { NotificationIcons } from '01-atoms/NotificationIcons/NotificationIcons'

import { LazyImage } from '02-molecules/LazyImage/LazyImage'

import { useConfigContext } from '09-providers/Config/Config.context'

import imagesStyles from '10-styles/App/Images.module.css'
import statusStyles from '10-styles/Status.module.css'

import notificationsStyles from './NotificationItem.module.scss'
import { type NotificationItemProps } from './NotificationItem.types'

const imageSize = 64

const debug = getDebugger('composites:NotificationItem:NotificationItem')

const parseNotificationData = (notificationData: string): NotificationData => {
    let data = {
        profileImage: '',
        profileClassName: '',
        profileName: '',
        profileOnline: '',
        type: ''
    }

    try {
        data = JSON.parse(notificationData) as NotificationData
    } catch (err) {
        debug.log('parseNotificationData', 'error:', (err as Error).message)
    }

    return data
}

export const NotificationItem: FC<NotificationItemProps> = ({ notification, removeNotification, scaling, styleSelect }) => {
    const { Config } = useConfigContext()

    const notificationsMarkReadURL = useMarkNotificationsReadURL()

    styleSelect = styleSelect ?? {}
    scaling = scaling ?? false // NOSONAR

    const _notificationData: NotificationData = parseNotificationData(notification.data)

    const _friendlyType =
        _notificationData.type != null && Constants.Notifications.Translations[_notificationData.type] != null
            ? Constants.Notifications.Translations[_notificationData.type]
            : 'interacted with your profile'

    const [_isRead, setRead] = useState<boolean>(Boolean(notification.read))

    const markRead = async (): Promise<void> => {
        const result = await postWithParams(notificationsMarkReadURL, { uuid: notification.uuid })

        if (result.error == null && result.code === 'SUCCESS') {
            setRead(true)
        }
    }

    const removeNotificationWrapper = (e: OnChangeMouseEvent<SVGSVGElement>): void => {
        e.preventDefault()

        removeNotification(notification)
    }

    // const profileUri = `/profile/${notification.profileId}`

    const {
        NotificationItemWrapperStyle,
        NotificationItemIconStyle,
        NotificationItemImageStyle,
        NotificationItemStatusStyle,
        MemberStatusStyle,
        NotificationItemMessageStyle,
        NotificationItemDescriptionStyle,
        NotificationItemTimelogStyle,
        NotificationItemTimeContentStyle,
        NotificationItemActionsStyle,
        NotificationItemActionStyle
    } = styleSelect

    // const responsiveProps = scaling ? { xs: 12, sm: 6, md: 4, lg: 3 } : { col: 12 }

    return (
        <div
            className={clsx(
                notificationsStyles.NotificationItemWrapper,
                _isRead ? notificationsStyles.NotificationItemRead : notificationsStyles.NotificationItemUnread,
                NotificationItemWrapperStyle
            )}
        >
            <div className={clsx(notificationsStyles.NotificationItemIcon, NotificationItemIconStyle)}>
                <NotificationIcons type={_notificationData.type} />
            </div>
            <div
                className={clsx(
                    statusStyles[_notificationData.profileClassName],
                    notificationsStyles.NotificationItemImage,
                    notificationsStyles[_notificationData.profileClassName],
                    NotificationItemImageStyle
                )}
            >
                <LazyImage
                    className={clsx(imagesStyles.ImageResponsive)}
                    src={
                        _notificationData.profileImage !== null
                            ? `${Config.urls.resizeImagesUrl}/${_notificationData.profileImage}/${imageSize}/${imageSize}`
                            : profileImageFallback
                    }
                />
            </div>
            <div className={clsx(notificationsStyles.NotificationItemStatus, NotificationItemStatusStyle)}>
                <span className={clsx(notificationsStyles.MemberStatus, notificationsStyles[_notificationData.profileOnline], MemberStatusStyle)} />
            </div>
            <div className={clsx(notificationsStyles.NotificationItemMessage, NotificationItemMessageStyle)}>
                <div className={clsx(notificationsStyles.NotificationItemDescription, NotificationItemDescriptionStyle)}>{`${_notificationData.profileName} ${_friendlyType}`}</div>
                <div className={clsx(notificationsStyles.NotificationItemTimelog, 'force-default-color', NotificationItemTimelogStyle)}>
                    <TimeAgo className={clsx(notificationsStyles.NotificationItemTimeContent, NotificationItemTimeContentStyle)} date={new Date(notification.created)} />
                </div>
            </div>
            <div className={clsx(notificationsStyles.NotificationItemActions, NotificationItemActionsStyle)}>
                <div className={clsx(notificationsStyles.NotificationItemAction, NotificationItemActionStyle)}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        aria-hidden='true'
                        onClick={(e: OnChangeMouseEvent<SVGSVGElement>) => {
                            removeNotificationWrapper(e)
                        }}
                    />
                </div>
                <div className={clsx(notificationsStyles.NotificationItemAction, NotificationItemActionStyle)}>
                    <FontAwesomeIcon
                        icon={_isRead ? faEnvelopeOpen : faEnvelope}
                        aria-hidden='true'
                        onClick={
                            !_isRead
                                ? () => {
                                      void markRead()
                                  }
                                : undefined
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
