import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type ResetPasswordProps } from './ResetPassword.types'

const LazyComponent = lazy(async () => await import('./ResetPassword'))

export const ResetPassword = (props: JSX.IntrinsicAttributes & ResetPasswordProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default ResetPassword
