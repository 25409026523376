import React, { type FC } from 'react'

import { Alert, Col, Container, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom'

import { useAppState } from '20-lib/hooks'

import { Conditional } from '01-atoms/Conditional/Conditional'

import { Tip } from './Tip/Tip'
import { type TipsProps } from './Tips.types'

export const Tips: FC<TipsProps> = () => {
    const appContext = useAppState()

    const location = useLocation()

    const showTips = true

    const tips = []

    if (!appContext.verifiedEmail) {
        tips.push(
            <Tip key='verifyEmailTip' variant='danger'>
                Please Verify Your Email by Clicking{' '}
                <LinkContainer to='/settings/verify-email'>
                    <Alert.Link>HERE</Alert.Link>
                </LinkContainer>
            </Tip>
        )
    }

    if ((appContext.isNotGuy && !appContext.hasProfileImage && appContext.geoLocalize && /\/(my-profile)/.exec(location.pathname) === null) || appContext.override) {
        tips.push(
            <Tip key='profilePictureTip'>
                Go to the{' '}
                <LinkContainer to='/my-profile'>
                    <Alert.Link>My Profile</Alert.Link>
                </LinkContainer>{' '}
                page, upload pics and edit the info. This is what the Foreigners will see.
            </Tip>
        )
    }

    if ((appContext.isNotGuy && !appContext.isKaPartner && appContext.geoLocalize) || appContext.override) {
        tips.push(
            <Tip key='kaPartnerTip'>
                Go to the{' '}
                <LinkContainer to='/ka-partner'>
                    <Alert.Link>Ka-Partner</Alert.Link>
                </LinkContainer>{' '}
                page, click anywhere on the orange and then apply (FREE) to be a Ka-Partner to start earning.
            </Tip>
        )
    }

    if ((appContext.isNotGuy && !appContext.isKaPartner && appContext.geoLocalize) || appContext.override) {
        tips.push(
            <Tip key='inviteTip' variant='danger'>
                <strong>Want to Earn $5.00 US?</strong> Go to the{' '}
                <LinkContainer to='/invite-and-earn-five-us-dollars'>
                    <Alert.Link>Invite New Member</Alert.Link>
                </LinkContainer>{' '}
                page!
            </Tip>
        )
    }

    let tip: JSX.Element | string = ''

    if (tips.length === 1) {
        tip = tips[0]
    } else {
        tip = tips[Math.floor(Math.random() * tips.length)]
    }

    const showTip =
        showTips && tips.length > 0 && /\/(get-verified-become-a-ka-partner|(sign-up|signup)|invite-and-earn-five-us-dollars|verify-email)/.exec(location.pathname) === null

    return (
        <Conditional condition={showTip}>
            <Container>
                <Row>
                    <Col>{tip}</Col>
                </Row>
            </Container>
        </Conditional>
    )
}

export default Tips
