import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type VerifyEmailProps } from './VerifyEmail.types'

const Lazy = lazy(async () => await import('./VerifyEmail'))

export const VerifyEmail = (props: JSX.IntrinsicAttributes & VerifyEmailProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <Lazy {...props} />
    </Suspense>
)

export default VerifyEmail
