import { useMemo } from 'react'

import useSWR from 'swr'

import { postWithParams } from '20-lib/api'
import { swrInLoadingState } from '20-lib/utils'
import { type APIGetNotificationsResult, type NotificationModel } from '20-types/fl'

import { useGetNotificationsURL, useRemoveNotificationsURL } from '../api'

import { type UseNotifications, type UseNotificationsFn } from './types'

export const useNotifications: UseNotificationsFn = (notificationsArgs): UseNotifications => {
    const page = notificationsArgs?.page ?? 1
    const recordsPerPage = notificationsArgs?.recordsPerPage ?? 100

    const removeNotificationsURL = useRemoveNotificationsURL()
    const notificationsURL = useGetNotificationsURL({ page, recordsPerPage })

    console.log(`useNotifications: notificationsURL := ${notificationsURL}`)

    const notificationsResult = useSWR<APIGetNotificationsResult>(notificationsURL)

    return useMemo(() => {
        const removeNotification = async (notificationData: NotificationModel): Promise<void> => {
            const removeNotificationResult = await postWithParams(removeNotificationsURL, { uuid: notificationData.uuid })

            if (removeNotificationResult.error == null && removeNotificationResult.code === 'SUCCESS') {
                if (notificationsResult.data != null)
                    await notificationsResult.mutate({
                        ...notificationsResult.data,
                        notifications: notificationsResult.data.notifications.filter((notification) => notification.uuid !== notificationData.uuid)
                    })
            }
        }

        return {
            notifications: notificationsResult.data?.notifications ?? [],
            removeNotification,
            isInFlight: swrInLoadingState(notificationsResult)
        }
    }, [notificationsResult, removeNotificationsURL])
}
