import { useEffect } from 'react'

import useSWR from 'swr'

import { getWithParams } from '20-lib/api'
import { usePSM } from '20-lib/external/psm'
import { coerceString } from '20-lib/utils'
import { type APINewNotificationsResult } from '20-types/fl'

import { useNewNotificationsURL } from '../api'

import { type UseNewNotifications } from './types'

export const useNewNotifications = (): UseNewNotifications => {
    const [_lastNotificationTimestamp, setLastNotificationTimestamp] = usePSM<number>('lastNotificationTimestamp', 0)

    const [newNotificationsURL] = useNewNotificationsURL({ timestamp: coerceString(_lastNotificationTimestamp) })

    const fetchWithTimestamp = async (url: string): Promise<APINewNotificationsResult> => {
        return (await getWithParams<APINewNotificationsResult>(url, { timestamp: _lastNotificationTimestamp.toString() })) as APINewNotificationsResult
    }

    const notificationsResult = useSWR<APINewNotificationsResult>(newNotificationsURL, fetchWithTimestamp, { refreshInterval: 15000, revalidateIfStale: false })

    useEffect(() => {
        if (notificationsResult.data != null) setLastNotificationTimestamp(notificationsResult.data.timestamp)
    }, [notificationsResult, setLastNotificationTimestamp])

    return notificationsResult
}
