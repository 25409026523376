import { toast as realToast, type ToastItem } from 'react-toastify'

import { getDebugger } from '../debug'

const toastStates: Array<number | string> = []

const debug = getDebugger('lib:toast:common')

const toastUpdater = (payload: ToastItem): void => {
    debug.extend('updater').log('payload:', payload)

    switch (payload.status) {
        case 'added':
            if (!toastStates.includes(payload.id)) {
                toastStates.push(payload.id)
            }
            break
        case 'removed':
            if (toastStates.includes(payload.id)) {
                toastStates.splice(toastStates.indexOf(payload.id), 1)
            }
            break
    }

    debug.extend('updater').log('toastStates:', toastStates)
}

realToast.onChange(toastUpdater)

export const clearAllToasts = (): void => {
    debug.extend('clearAllToasts').log('clearing all toasts')

    toastStates.forEach((toastId) => {
        debug.extend('clearAllToasts').log('dismissing', toastId)
        realToast.dismiss(toastId)
    })
}

export const toast = realToast
