import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectLogin } from '01-atoms/RedirectLogin/RedirectLogin'
import { RedirectSignupStep2 } from '01-atoms/RedirectSignupStep2/RedirectSignupStep2'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { SignupStep3 as GatedComponent } from './SignupStep3.lazy'
import { type SignupStep3Props } from './SignupStep3.types'

export const SignupStep3: FC<SignupStep3Props> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='SignupStep3.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectLogin />

    if (appContext.requireProfile) return <RedirectSignupStep2 />

    return <GatedComponent {...props} />
}

export default SignupStep3
