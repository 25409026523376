/* eslint-disable react/jsx-pascal-case */
import React from 'react'

import { Navigate, createBrowserRouter } from 'react-router-dom'

import { RedirectChangePassword } from '01-atoms/RedirectChangePassword/RedirectChangePassword'
import { RedirectSearch } from '01-atoms/RedirectSearch/RedirectSearch'

import { MainLayout } from '06-layouts/MainLayout/MainLayout'

import { Admin } from '07-pages/Admin/Admin.gated'
import { BuyCredits } from '07-pages/Commerce/BuyCredits/BuyCredits.gated'
import { Credits } from '07-pages/Commerce/Credits/Credits.gated'
import { CreditsPayout } from '07-pages/Commerce/CreditsPayout/CreditsPayout.gated'
import { Plans } from '07-pages/Commerce/Plans/Plans.gated'
import { Premium } from '07-pages/Commerce/Premium/Premium.gated'
import { Purchase } from '07-pages/Commerce/Purchase/Purchase.gated'
import { DatingProfile } from '07-pages/Dating/DatingProfile/DatingProfile.gated'
import { Search } from '07-pages/Dating/Search/Search.gated'
import { DatingTips } from '07-pages/DatingTips/DatingTips.lazy'
import { DatingTipsPhilippines } from '07-pages/DatingTips/DatingTipsPhilippines/DatingTipsPhilippines.gated'
import { DatingTipsWorld } from '07-pages/DatingTips/DatingTipsWorld/DatingTipsWorld.gated'
import { AboutUs } from '07-pages/Public/AboutUs/AboutUs.lazy'
import { Cookies } from '07-pages/Public/Cookies/Cookies.lazy'
import { FAQ } from '07-pages/Public/FAQ/FAQ.lazy'
import { Landing } from '07-pages/Public/Landing/Landing.gated'
import { PrivacyPolicy } from '07-pages/Public/PrivacyPolicy/PrivacyPolicy.lazy'
import { Sitemap } from '07-pages/Public/Sitemap/Sitemap.lazy'
import { TermsOfServices } from '07-pages/Public/TermsOfServices/TermsOfServices.lazy'
import { Chats } from '07-pages/User/Chats/Chats.gated'
import { EmailOTP } from '07-pages/User/EmailOTP/EmailOTP.lazy'
import { Executive } from '07-pages/User/Executive/Executive.gated'
import { ForgotPassword } from '07-pages/User/ForgotPassword/ForgotPassword.lazy'
import { GetVerified } from '07-pages/User/GetVerified/GetVerified.gated'
import { Gifts } from '07-pages/User/Gifts/Gifts.gated'
import { KaPartner } from '07-pages/User/KaPartner/KaPartner.gated'
import { Likes } from '07-pages/User/Lists/Likes/Likes.gated'
import { Visits } from '07-pages/User/Lists/Visits/Visits.gated'
import { Login } from '07-pages/User/Login/Login.gated'
import { Notifications } from '07-pages/User/Notifications/Notifications.gated'
import { Invite } from '07-pages/User/Recruitment/Invite/Invite.gated'
import { Recruit } from '07-pages/User/Recruitment/Recruit/Recruit.gated'
import { SocialShare } from '07-pages/User/Recruitment/SocialShare/SocialShare.gated'
import { ResetPassword } from '07-pages/User/ResetPassword/ResetPassword.lazy'
import { Settings } from '07-pages/User/Settings/Settings.gated'
import { SignupStep1 } from '07-pages/User/Signup/SignupStep1/SignupStep1.gated'
import { SignupStep2 } from '07-pages/User/Signup/SignupStep2/SignupStep2.gated'
import { SignupStep3 } from '07-pages/User/Signup/SignupStep3/SignupStep3.gated'
import { UserProfile } from '07-pages/User/UserProfile/UserProfile.gated'
import { VerifyEmail } from '07-pages/User/VerifyEmail/VerifyEmail.lazy'

const AppRouter = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: '', index: true, element: <RedirectSearch /> },

            {
                path: 'admin',
                children: [
                    { path: '', index: true, element: <Admin /> },
                    {
                        path: ':activeKey',
                        children: [
                            {
                                path: '',
                                index: true,
                                element: <Admin />
                            },
                            {
                                path: ':subKey',
                                element: <Admin />
                            }
                        ]
                    }
                ]
            },

            { path: 'buy-credits', element: <BuyCredits /> },
            { path: 'change-password', element: <RedirectChangePassword /> },
            { path: 'credits', element: <Credits /> },
            { path: 'credits-payout', element: <CreditsPayout /> },
            { path: 'how-to-date-a-filipina', element: <DatingTipsWorld /> },
            { path: 'kung-paano-makipag-date-sa-isang-dayuhan', element: <DatingTipsPhilippines /> },
            { path: 'datingtips', element: <DatingTips /> },
            { path: 'executive', element: <Executive /> },
            { path: 'invite-and-earn-five-us-dollars', element: <Recruit /> },
            { path: 'get-verified-become-a-ka-partner', element: <GetVerified /> },
            { path: 'gifts', element: <Gifts /> },
            { path: 'home', element: <RedirectSearch /> },
            { path: 'ka-partner', element: <KaPartner /> },
            {
                path: 'likes',
                children: [
                    { path: '', index: true, element: <Likes /> },
                    { path: ':dataSource', element: <Likes /> }
                ]
            },
            {
                path: 'visits',
                children: [
                    { path: '', index: true, element: <Visits /> },
                    { path: ':dataSource', element: <Visits /> }
                ]
            },
            { path: 'chat', element: <Chats /> },
            { path: 'me', element: <UserProfile /> },
            { path: 'my-profile', element: <UserProfile /> },
            { path: 'post-profile-and-earn', element: <UserProfile /> },
            { path: 'premium', element: <Premium /> },
            { path: 'purchase/:productType/:productId', element: <Purchase /> },
            { path: 'purchase', element: <Purchase /> },
            { path: 'plans', element: <Plans /> },

            {
                path: 'find-my-cupid-match',
                element: <Search />
            },
            {
                path: 'profile/:id',
                element: <DatingProfile />
            },

            {
                path: 'settings',
                children: [
                    { path: '', index: true, element: <Settings /> },
                    {
                        path: ':activeKey',
                        children: [
                            {
                                path: '',
                                index: true,
                                element: <Settings />
                            },
                            {
                                path: ':subKey',
                                element: <Settings />
                            }
                        ]
                    }
                ]
            },

            { path: 'login', element: <Login /> },
            { path: 'notifications', element: <Notifications /> },
            { path: 'sign-up', element: <SignupStep1 /> },
            { path: 'signup', element: <SignupStep1 /> },
            { path: 'sign-up-step-1', element: <SignupStep1 /> },
            { path: 'signup/invite/:token', element: <SignupStep1 /> },
            { path: 'sign-up/invite/:token', element: <SignupStep1 /> },

            { path: 'signup-step-2', element: <SignupStep2 /> },
            { path: 'sign-up-step-2', element: <SignupStep2 /> },
            { path: 'signup-creating-unique-profile', element: <SignupStep2 /> },
            { path: 'sign-up-creating-unique-profile', element: <SignupStep2 /> },
            { path: 'sign-up-create-unique-profile', element: <SignupStep2 /> },

            { path: 'sign-up-step-3', element: <SignupStep3 /> },
            { path: 'signup-done', element: <SignupStep3 /> },
            { path: 'sign-up-done', element: <SignupStep3 /> },
            { path: 'sign-up-confirmation-of-real-person', element: <SignupStep3 /> },

            { path: 'reset-password/:token', element: <ResetPassword /> },
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'email-otp', element: <EmailOTP /> },
            { path: 'invite/:token', element: <Invite /> },
            { path: 'your-official-invite/:token', element: <Invite /> },
            { path: 'social-share', element: <SocialShare /> },
            { path: 'verify-email/:token', element: <VerifyEmail /> },
            { path: 'forgot-password', element: <ForgotPassword /> },

            { path: 'FAQ-Photos-Pictures-Why-to-like-Filipino-Woman.html', element: <FAQ /> }, // NOSONAR
            { path: 'aboutus', element: <AboutUs /> },
            { path: 'aboutUs', element: <AboutUs /> },
            { path: 'about-us', element: <AboutUs /> },
            { path: 'cookies', element: <Cookies /> },
            { path: 'faq', element: <FAQ /> }, // NOSONAR

            { path: 'welcome', element: <Landing /> },
            { path: 'privacypolicy', element: <PrivacyPolicy /> },
            { path: 'privacy.html', element: <PrivacyPolicy /> },
            { path: 'terms', element: <TermsOfServices /> },
            { path: 'terms.html', element: <TermsOfServices /> },
            { path: 'sitemap', element: <Sitemap /> },

            { path: 'get-verified', element: <Navigate to='/get-verified-become-a-ka-partner' /> },
            { path: 'search-browse', element: <Navigate to='/find-my-cupid-match' replace /> },

            { path: '*', element: <Navigate to='/' replace /> }
        ]
    }
])

export default AppRouter
export { AppRouter }
