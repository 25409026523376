import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type InviteProps } from './Invite.types'

const LazyComponent = lazy(async () => await import('./Invite'))

export const Invite = (props: JSX.IntrinsicAttributes & InviteProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Invite
