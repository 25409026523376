import React, { type FC } from 'react'

import { faComments, faEye, faFlag, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import styles from './NotificationIcons.module.css'
import { type NotificationIconsProps } from './NotificationIcons.types'

export const NotificationIcons: FC<NotificationIconsProps> = ({ type, className }) => {
    type = type ?? 'default'

    let icon

    switch (type) {
        case 'message':
            icon = faComments
            break
        case 'like':
        case 'profilelike':
            icon = faHeart
            break
        case 'profilevisit':
        case 'view':
            icon = faEye
            break
        default:
            icon = faFlag
            break
    }

    return <FontAwesomeIcon className={clsx(styles.NotificationIcon, className)} icon={icon} />
}

export default NotificationIcons
