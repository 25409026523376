import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectLogin } from '01-atoms/RedirectLogin/RedirectLogin'
import { RedirectRoot } from '01-atoms/RedirectRoot/RedirectRoot'
import { RedirectSignupStep2 } from '01-atoms/RedirectSignupStep2/RedirectSignupStep2'
import { RedirectSignupStep3 } from '01-atoms/RedirectSignupStep3/RedirectSignupStep3'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Plans as GatedComponent } from './Plans.lazy'
import { type PlansProps } from './Plans.types'

export const Plans: FC<PlansProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Plans.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectLogin />

    if (appContext.requireProfile) return <RedirectSignupStep2 />

    if (appContext.requireActivation) return <RedirectSignupStep3 />

    if (appContext.isNotGuy && !appContext.isAdmin) return <RedirectRoot />

    return <GatedComponent {...props} />
}

export default Plans
