const defaultValidLoginMethods = ['email', 'facebook', 'google']

export const parseValidLoginMethods = (inp?: string | string[]): string[] => {
    if (inp == null) return defaultValidLoginMethods

    if (!Array.isArray(inp)) return inp.split(',')

    return inp
}

export const safeURLJoin = (primaryUrl: string | undefined, backupUrl: string): string => (primaryUrl ?? backupUrl).replace(/\/$/, '')
