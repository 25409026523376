import React, { type FC, Fragment, useEffect, useState } from 'react'

import { faCheckCircle, faCrown, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import { postWithParams } from '20-lib/api'
import { getDebugger } from '20-lib/debug'
import { useBlockProfileURL, useLikeProfileURL, useReportProfileURL } from '20-lib/hooks/api/urls'
import { toast } from '20-lib/toast'
import { getLocationFromPerson, getParsedProfileTypes, profileImageFallback } from '20-lib/ui'
import { type ProfileBriefModel } from '20-types/fl/database'

import { Conditional } from '01-atoms/Conditional/Conditional'

import { LazyImage } from '02-molecules/LazyImage/LazyImage'

import { FlagWrapper } from '03-particles/FlagWrapper/FlagWrapper'

import { useConfigContext } from '09-providers/Config/Config.context'

import commonStyles from '10-styles/App/Common.module.css'

import { LikeProfileButton } from './LikeProfileButton/LikeProfileButton'
import styles from './ProfileCard.module.css'
import { type ProfileCardProps } from './ProfileCard.types'
import { ReportBlockButton } from './ReportBlockButton/ReportBlockButton'

const debug = getDebugger('composites:ProfileCard:ProfileCard')

export const ProfileCard: FC<ProfileCardProps> = ({ person, wrapped, myProfile, onClick }) => {
    const { Config } = useConfigContext()

    const profilesLikeProfileURL = useLikeProfileURL()
    const profilesBlockProfileURL = useBlockProfileURL()
    const profilesReportProfileURL = useReportProfileURL()

    wrapped = wrapped ?? true
    myProfile = myProfile ?? false

    const navigate = useNavigate()

    const [_showBlockReportModal, setShowBlockReportModal] = useState(false)
    const [_person, updatePerson] = useState<ProfileBriefModel>(person)

    const toggleBlockReportModal = (): void => {
        setShowBlockReportModal(!_showBlockReportModal)
    }

    const likeProfile = async (): Promise<void> => {
        toast.success(
            _person.liked
                ? 'Unliking profile'
                : "Your like of this profile is on its way to the server! You can LIKE or show Interest in other profiles while you're waiting to hear back!",
            { autoClose: 10000 }
        )

        const result = await postWithParams(profilesLikeProfileURL, { profileId: _person.profileId })

        if (result.error == null && result.result === 'OK') {
            updatePerson({ ..._person, liked: !_person.liked })
        }
    }

    const blockUserProfile = async (): Promise<void> => {
        const result = await postWithParams(profilesBlockProfileURL, { profileId: _person.profileId })

        if (result.error == null && result.result === 'OK') {
            toast.success('Profile blocked!')
            toggleBlockReportModal()
            navigate('/find-my-cupid-match', { replace: true })
        } else {
            debug.log('blockUserProfile', 'result.error:', result.error, 'result.message:', result.message)
            toast.error('Sorry, something went wrong while blocking this profile')
        }
    }

    const reportUserProfile = async (): Promise<void> => {
        const result = await postWithParams(profilesReportProfileURL, { profileId: _person.profileId })

        if (result.error == null && result.result === 'OK') {
            toast.success('Profile reported!')
            toggleBlockReportModal()
            navigate('/')
        } else {
            debug.log('reportUserProfile', 'result.error:', result.error, 'result.message:', result.message)
            toast.error('Sorry, something went wrong while reporting this profile')
        }
    }

    const getPremiumBadgeStyle = (className: string): string => {
        let PremiumBadgeStyle

        switch (className) {
            case 'status-trial':
                PremiumBadgeStyle = commonStyles.PremiumBadgeTrial
                break
            case 'status-premium-new':
                PremiumBadgeStyle = commonStyles.PremiumBadgeNew
                break
            case 'status-premium-regular':
                PremiumBadgeStyle = commonStyles.PremiumBadgeRegular
                break
            case 'status-executive':
                PremiumBadgeStyle = commonStyles.ExecutiveBadge
                break
            default:
                PremiumBadgeStyle = commonStyles.PremiumBadge
        }

        return PremiumBadgeStyle
    }

    useEffect(() => {
        if (JSON.stringify(_person) !== JSON.stringify(person)) updatePerson(person)
    }, [_person, person])

    if (_person.userName === null) {
        return null
    }

    const { isPremium, profileTypeTitle, profileTypeIndicator, profileTypeClassName } = getParsedProfileTypes(_person)

    const profileImageSize = myProfile ? 250 : 185

    const profileImage =
        (_person.profileImage !== null || _person.profileImage != null) && Config.urls?.resizeImagesUrl !== null && Config.urls.resizeImagesUrl !== ''
            ? `${Config.urls.resizeImagesUrl}/${_person.profileImage}/${profileImageSize}/${profileImageSize}`
            : profileImageFallback

    const baseProfileImageComponent = (
        <LazyImage src={[profileImage, profileImageFallback]} className={clsx(styles.SearchProfilePic, !myProfile && styles.SearchProfilePicHeight)} />
    )

    let profileImageComponent = baseProfileImageComponent

    if (onClick != null) {
        profileImageComponent = (
            <LazyImage src={[profileImage, profileImageFallback]} className={clsx(styles.SearchProfilePic, !myProfile && styles.SearchProfilePicHeight)} onClick={onClick} />
        )
    } else if (!myProfile) {
        profileImageComponent = <Link to={`/profile/${_person.profileId}`}>{baseProfileImageComponent}</Link>
    }

    const profileContent = (
        <div className={clsx(styles.MemberBox, styles.MemBox, profileTypeClassName)}>
            <Row className={clsx(styles.MemberTypeHeader)}>
                <Col className={clsx('member-type')}>
                    <Image src={profileTypeIndicator} style={{ paddingRight: 8 }} />
                    <span>{profileTypeTitle}</span>
                </Col>
            </Row>

            <Row className={clsx(styles.MemberPicRow)}>
                <Col>
                    <div className={clsx('MemberPic')}>
                        {profileTypeClassName === 'status-staff' ? (
                            <div className={clsx(styles.staff)} data-toggle='tooltip' data-placement='top' title='Staff'>
                                <FontAwesomeIcon className={clsx(commonStyles.StaffBadge)} icon={faUserShield} aria-hidden='true' />
                            </div>
                        ) : null}
                        {_person.kaPartner != null && _person.kaPartner ? (
                            <div className={clsx('verified')} data-toggle='tooltip' data-placement='top' title='Verified'>
                                <FontAwesomeIcon icon={faCheckCircle} aria-hidden='true' />
                            </div>
                        ) : null}
                        {isPremium ? (
                            <div data-toggle='tooltip' data-placement='top' title='Premium'>
                                <FontAwesomeIcon className={clsx(getPremiumBadgeStyle(_person.className))} icon={faCrown} aria-hidden='true' />
                            </div>
                        ) : null}
                        {profileImageComponent}
                    </div>
                </Col>
            </Row>

            <Row className={clsx(commonStyles.MemberInfo)}>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <span className={clsx('member-status', _person.online ?? 'offline', 'pull-left')} data-toggle='tooltip' data-placement='top' title='Offline' />
                                <h4 className={styles.ScaledUserName}>{_person.userName}</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={clsx(styles.ProfileDetailMainSection)}>
                                    <Conditional condition={!myProfile}>
                                        <div className={clsx(styles.ProfileDetailActionButtonContainer)}>
                                            <ReportBlockButton
                                                onClick={() => {
                                                    toggleBlockReportModal()
                                                }}
                                            />
                                        </div>
                                    </Conditional>

                                    <div className={clsx(styles.ProfileInfoContainer, myProfile ? styles.ProfileInfoContainerWide : styles.ProfileInfoContainerNarrow)}>
                                        <h5>{_person.age}</h5>
                                        <h5>{getLocationFromPerson(_person)}</h5>
                                    </div>

                                    <Conditional condition={!myProfile}>
                                        <div className={clsx(styles.ProfileDetailActionButtonContainer)}>
                                            <LikeProfileButton
                                                onClick={() => {
                                                    void likeProfile()
                                                }}
                                                person={_person}
                                            />
                                        </div>
                                    </Conditional>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={clsx(styles.CountryDiv)}>
                                    {_person.countryName} <FlagWrapper countryShortCode={_person.countryShortCode} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Modal
                show={_showBlockReportModal}
                onHide={() => {
                    toggleBlockReportModal()
                }}
                centered
            >
                <Modal.Body>
                    <Row className={clsx(styles.ReportModelHeader)}>
                        <Col xs={11}>&nbsp;</Col>
                        <Col xs={1}>
                            <Button
                                variant='close'
                                className={clsx(styles.ModalCloseButton)}
                                aria-label='Close'
                                onClick={() => {
                                    toggleBlockReportModal()
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={clsx('spacier')}>
                        <Col>
                            <Button
                                className={clsx('line-out')}
                                onClick={() => {
                                    void blockUserProfile()
                                }}
                            >
                                Block this Profile
                            </Button>
                        </Col>
                    </Row>
                    <Row className={clsx('spacier')}>
                        <Col>
                            <Button
                                className={clsx('line-out')}
                                onClick={() => {
                                    void reportUserProfile()
                                }}
                            >
                                Report this Profile
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )

    return wrapped ? (
        <Fragment key={_person.profileId}>
            <Col xs={12} md={6} lg={3}>
                {profileContent}
            </Col>
        </Fragment>
    ) : (
        <Fragment key={_person.profileId}>{profileContent}</Fragment>
    )
}

export default ProfileCard
