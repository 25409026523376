import React, { type FC } from 'react'

import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { type MenuItemSet } from '20-types/fl/ui'

import { NavBar } from '05-materials/NavBar/NavBar'

import { type PublicNavBarProps } from './PublicNavBar.types'

const primaryMenuItems: MenuItemSet = [
    { type: 'link', link: { pathname: '/welcome', hash: '#how' }, title: <>How it Works</> },
    { type: 'link', link: { pathname: '/welcome', hash: '#why' }, title: <>Why FilipinaLuv</> },
    { type: 'link', link: { pathname: '/welcome', hash: '#about' }, title: <>About</> },
    { type: 'divider' },
    {
        type: 'link',
        link: '/login',
        title: (
            <>
                <FontAwesomeIcon icon={faLock} /> Login
            </>
        )
    }
]

export const PublicNavBar: FC<PublicNavBarProps> = () => {
    return <NavBar primaryMenuItems={primaryMenuItems} />
}

export default PublicNavBar
