import React, { type FC, useState } from 'react'

import clsx from 'clsx'
import { Alert, Button, Col, Row } from 'react-bootstrap'

import { type TipProps } from './Tip.types'

export const Tip: FC<TipProps> = ({ variant, children }) => {
    const [_showTip, setShowTip] = useState(true)

    const closeTip = (): void => {
        setShowTip(false)
    }

    return (
        <Alert
            variant={variant ?? 'success'}
            show={_showTip}
            onClose={() => {
                closeTip()
            }}
        >
            <Row>
                <Col>{children}</Col>
                <Col sm={2}>
                    <Button
                        className={clsx('pull-right')}
                        onClick={() => {
                            closeTip()
                        }}
                    >
                        Got It
                    </Button>
                </Col>
            </Row>
        </Alert>
    )
}

export default Tip
