import React, { type FC, useCallback, useState } from 'react'

import clsx from 'clsx'
import { Button } from 'react-bootstrap'

import { postWithParams } from '20-lib/api'
import { useAppState } from '20-lib/hooks'
import { useContactUsURL } from '20-lib/hooks/api/urls'
import { toast } from '20-lib/toast'

import { BrandedWordLuv } from '01-atoms/BrandedWordLuv/BrandedWordLuv'

import { type ContactUsProps } from './ContactUs.types'

import './ContactUs.css'

export const ContactUs: FC<ContactUsProps> = () => {
    const appState = useAppState()

    const contactUsURL = useContactUsURL()

    const [_name, setName] = useState('')
    const [_message, setMessage] = useState('')
    const [_email, setEmail] = useState('')
    const [_sendCopy, setSendCopy] = useState(false)

    const handleContactUs = useCallback(async (): Promise<void> => {
        if (_name === '' || _message === '' || _email === '') {
            toast.error('Please fill in all the fields')
            return
        }

        const result = await postWithParams(contactUsURL, {
            userId: appState.userInfo.userId,
            name: _name,
            message: _message,
            email: _email,
            sendCopy: _sendCopy
        })

        if (result.error == null && result.result === 'OK') {
            toast.success('Message sent!')
            setName('')
            setEmail('')
            setMessage('')
            setSendCopy(false)
        }
    }, [_name, _message, _email, contactUsURL, appState.userInfo.userId, _sendCopy])

    return (
        <div className={clsx('contact-us')}>
            <div className={clsx('contact-heading')}>
                <h3>Contact Us - Team FilipinaLuv</h3>
            </div>
            <p className={clsx('frontpage')}>
                Don&apos;t be shy, drop us an email and say hello! We&apos;d really <BrandedWordLuv capitalized /> to hear from you.
            </p>
            <form action='#' method='post'>
                <div className={clsx('form-group')}>
                    <input
                        className={clsx('form-control', 'italic')}
                        type='text'
                        name='name'
                        value={_name}
                        onChange={(e) => {
                            setName(e.target.value.trim())
                        }}
                        placeholder='Your Name'
                    />
                </div>
                <div className={clsx('form-group')}>
                    <input
                        className={clsx('form-control', 'italic')}
                        type='email'
                        value={_email}
                        onChange={(e) => {
                            setEmail(e.target.value.trim())
                        }}
                        name='email'
                        placeholder='Your E-mail'
                        autoComplete='email'
                    />
                </div>
                <div className={clsx('form-group')}>
                    <textarea
                        className={clsx('form-control', 'italic')}
                        name='message'
                        value={_message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        placeholder='Your Message'
                    />
                </div>
                <div className={clsx('form-group')}>
                    <input
                        type='checkbox'
                        id='contactSendCopy'
                        name='sendCopy'
                        checked={_sendCopy}
                        onChange={(e) => {
                            setSendCopy(e.target.checked)
                        }}
                    />
                    &nbsp;
                    <label htmlFor='contactSendCopy' className={clsx('contact_text')}>
                        {' '}
                        Send me a copy
                    </label>
                </div>

                <Button
                    className={clsx('btn', 'btn-yellow', 'centered')}
                    name='submit'
                    onClick={() => {
                        void handleContactUs()
                    }}
                >
                    Send Message
                </Button>
            </form>
        </div>
    )
}

export default ContactUs
