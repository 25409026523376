import { type MenuItem, type MenuItemSet } from '20-types/fl/ui'

export class MenuStore {
    private menuItems: MenuItemSet = []

    constructor(menuItems?: MenuItemSet) {
        if (menuItems != null) this.menuItems = Object.assign([], menuItems)
    }

    addItem(item: MenuItem, condition?: boolean): void {
        condition = condition ?? true

        if (condition) {
            this.menuItems.push(item)
        }
    }

    clearItems(): void {
        this.menuItems.splice(0, this.menuItems.length)
    }

    getItems(): MenuItemSet {
        return this.menuItems
    }

    insertItem(item: MenuItem, condition?: boolean): void {
        condition = condition ?? true

        if (condition) {
            this.menuItems.unshift(item)
        }
    }

    loadItems(menuItems: MenuItemSet): void {
        this.menuItems = Object.assign([], menuItems)
    }

    pileItems(menuItems: MenuItemSet): void {
        for (const menuItem of menuItems) {
            this.addItem(menuItem)
        }
    }
}
