import { type ProfileBriefModel, type ProfileInfoModel } from '20-types/fl/database'

import { assetFLHeart, assetFLHeartBW, assetFLQueerHeart, assetFLQueerHeartBW } from '.'

export const getLikedIconFromPerson = (person: ProfileBriefModel | ProfileInfoModel): string => {
    if (person.liked) {
        return person.gender === 2 ? assetFLQueerHeart : assetFLHeart
    } else {
        return person.gender === 2 ? assetFLQueerHeartBW : assetFLHeartBW
    }
}

export const getLikedAltTextFromPerson = (person: ProfileBriefModel | ProfileInfoModel): string => {
    if (person.liked) {
        return person.gender === 2 ? 'liked LGBTQIA*+ person' : 'liked person'
    } else {
        return person.gender === 2 ? 'have not liked LGBTQIA*+ person' : 'not liked person'
    }
}
