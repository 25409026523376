import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type DatingTipsPhilippinesProps } from './DatingTipsPhilippines.types'

const LazyComponent = lazy(async () => await import('./DatingTipsPhilippines'))

export const DatingTipsPhilippines = (props: JSX.IntrinsicAttributes & DatingTipsPhilippinesProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default DatingTipsPhilippines
