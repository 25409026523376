import React, { type JSXElementConstructor, lazy, type ReactElement, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type LandingProps } from './Landing.types'

const LazyComponent = lazy(async () => await import('./Landing'))

export const Landing = (props: JSX.IntrinsicAttributes & LandingProps): ReactElement<unknown, string | JSXElementConstructor<unknown>> => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Landing
