import { type AdminSearchProfileModel, type ProfileBriefModel } from '20-types/fl/database'

import { getDebugger } from '../debug'

import { assetBGGF, assetCasualRelationshipIcon, assetFriendshipIcon, assetMarriageIcon } from '.'

const debug = getDebugger('lib:ui:profile')

interface IGetParsedProfileTypes {
    isPremium: boolean
    profileTypeTitle: string
    profileTypeIndicator: string
    profileTypeClassName: string
}

export const getParsedProfileTypes = (person: AdminSearchProfileModel | ProfileBriefModel): IGetParsedProfileTypes => {
    const isPremium = person.className?.startsWith('status-premium') || person.className?.startsWith('status-executive') || person.className?.startsWith('status-trial')

    let profileTypeTitle = 'Casual Dating'
    let profileTypeIndicator = assetCasualRelationshipIcon
    let profileTypeClassName = person.className

    if (isPremium) {
        if (person.lfSerious) {
            profileTypeClassName = 'status-serious'
        } else if (person.lfRelationship) {
            profileTypeClassName = 'status-relationship'
        } else if (person.lfCasual) {
            profileTypeClassName = 'status-casual'
        } else if (person.lfFriendship) {
            profileTypeClassName = 'status-friendship'
        }
    }

    if (person.className === 'status-staff') {
        profileTypeTitle = 'Staff'
        profileTypeIndicator = assetFriendshipIcon
    } else if (person.className === 'status-executive') {
        profileTypeClassName = 'status-executive'
        profileTypeTitle = 'I date to Marry ONLY'
        profileTypeIndicator = assetMarriageIcon
    } else if (person.lfRelationship) {
        profileTypeTitle = 'BF/GF'
        profileTypeIndicator = assetBGGF
    } else if (person.lfSerious) {
        profileTypeTitle = 'I date to Marry ONLY'
        profileTypeIndicator = assetMarriageIcon
    } else if (person.lfFriendship) {
        profileTypeTitle = 'Friendship'
        profileTypeIndicator = assetFriendshipIcon
    } else {
        debug.log('Falling back to casual for profile:', person.profileId, '->', { ...person })
    }

    return { isPremium, profileTypeTitle, profileTypeIndicator, profileTypeClassName }
}
