import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type NotificationsProps } from './Notifications.types'

const LazyComponent = lazy(async () => await import('./Notifications'))

export const Notifications = (props: JSX.IntrinsicAttributes & NotificationsProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Notifications
