import React, { type FC } from 'react'

import { type ConditionalProps } from './Conditional.types'

export const Conditional: FC<ConditionalProps> = ({ children, condition }) => {
    if (condition) {
        return <>{children}</>
    }

    return null
}

export default Conditional
