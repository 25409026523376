import { useMemo } from 'react'

import useSWR from 'swr'

import { type GeoAPIIPResult } from '20-types/fl'
import { type IGeoStoreData } from '20-types/fl/misc/services'

import { useConfigContext } from '09-providers/Config/Config.context'

const defaultGeoData: IGeoStoreData = {
    country: null,
    country_code: null,
    country_name: null,
    city: null,
    postal: null,
    ip: null,
    region: null,
    timezone: null,
    loc: null,
    org: null,
    localize: false,
    location: ''
}

export const useGeoData = (): IGeoStoreData => {
    const { Config } = useConfigContext()

    const geoDataResult = useSWR<GeoAPIIPResult>(`${Config.geo.url}/ip`, { refreshInterval: (Config.geo.cache.timeout ?? 3600) * 1000 })

    return useMemo<IGeoStoreData>(
        () => (!geoDataResult.isLoading && geoDataResult.data != null ? { ...defaultGeoData, ...geoDataResult.data.data } : defaultGeoData),
        [geoDataResult.data, geoDataResult.isLoading]
    )
}
