import React, { type FC } from 'react'

import { type ProviderComposerProps } from './ProviderComposer.types'

export const ProviderComposer: FC<ProviderComposerProps> = (props) => {
    const { providers = [], children } = props

    const compoundedProviders = providers.reduceRight((acc, Comp) => {
        const [Provider, providerProps] = Array.isArray(Comp) ? Comp : [Comp]

        return <Provider {...providerProps}>{acc}</Provider>
    }, children)

    return <>{compoundedProviders}</>
}

export default ProviderComposer
