import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type UserProfileProps } from './UserProfile.types'

const Lazy = lazy(async () => await import('./UserProfile'))

export const UserProfile = (props: JSX.IntrinsicAttributes & UserProfileProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <Lazy {...props} />
    </Suspense>
)

export default UserProfile
