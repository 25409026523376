import { useCallback, useEffect, useState } from 'react'

import stateMachine, { type PrettyStateMachine } from 'pretty-state-machine'

export type PSM_Object = ReturnType<PrettyStateMachine['set']>

// type PSM_Update_Value<T> = T | ((oldValue: T) => T)
type PSM_Update_Function<T> = (valueUpdate: T) => T
type PSM_Update_Value<T> = T | PSM_Update_Function<T>

export type PSM_Action<T> = (valueAction: PSM_Update_Value<T>) => PSM_Object

export type PSM_Use<T> = [T, PSM_Action<T>]

function isPSMUpdateFunction<T>(value: unknown): value is PSM_Update_Function<T> {
    return typeof value === 'function'
}

export const usePSM = <T = unknown>(key: string, defaultValue: T): PSM_Use<T> => {
    const [value, setValue] = useState<T>(stateMachine.get<T>(key, defaultValue))

    useEffect(() => {
        const listener = (newValue: Record<string, T>): void => {
            if (newValue[key] !== value) setValue(newValue[key])
        }

        stateMachine.sub(key, listener)

        return () => {
            stateMachine.unsub(key, listener)
        }
    }, [key, value])

    const set = useCallback(
        (valueUpdate: PSM_Update_Value<T>): PSM_Object => {
            setValue(valueUpdate)

            if (isPSMUpdateFunction<T>(valueUpdate)) return stateMachine.pub(key, valueUpdate(value))
            else return stateMachine.pub(key, valueUpdate)
        },
        [key, value]
    )

    return [value, set]
}
