import { createContext, useContext } from 'react'

import { type ConfigContextType } from './ConfigProvider.types'

export const ConfigContext = createContext<ConfigContextType | undefined>(undefined)

export const useConfigContext = (): ConfigContextType => {
    const configContext = useContext(ConfigContext)

    if (configContext === undefined) {
        throw new Error('useConfigContext must be used within a ConfigProvider')
    }

    return configContext
}
