import React, { type FC, useMemo } from 'react'

import clsx from 'clsx'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { usePSM } from '20-lib/external/psm'
import { useNewNotifications } from '20-lib/hooks/notifications'
import { type NotificationModel } from '20-types/fl/database'

import { NotificationItem } from '04-composites/NotificationItem/NotificationItem'

import styles from './Notifier.module.scss'
import { type NotifierProps } from './Notifier.types'

const notificationTimeout = 500

export const Notifier: FC<NotifierProps> = ({ className, style }) => {
    const [_filterIds, setFilterIds] = usePSM<string[]>('notification-menu-filterIds', [])

    const notificationsResult = useNewNotifications()

    const removeNotification = (notificationData: NotificationModel): void => {
        setFilterIds((filterIds) => (filterIds.includes(notificationData.uuid) ? filterIds : [...filterIds, notificationData.uuid]))
    }

    const notifications = useMemo(() => {
        return notificationsResult.data == null
            ? []
            : (notificationsResult.data.notifications ?? []).filter((notification: NotificationModel) => _filterIds.includes(notification.uuid))
    }, [_filterIds, notificationsResult.data])

    return (
        <div className={clsx(styles.NotificationOverlay, className)} style={style}>
            <TransitionGroup className={clsx(styles.NotificationList)}>
                {notifications.map((notification) => (
                    <CSSTransition
                        key={notification.uuid}
                        timeout={notificationTimeout}
                        classNames='item'
                        onExited={() => {
                            removeNotification(notification)
                        }}
                    >
                        <NotificationItem
                            notification={notification}
                            removeNotification={(notification: NotificationModel) => {
                                removeNotification(notification)
                            }}
                            scaling
                        />
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )
}

export default Notifier
