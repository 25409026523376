import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type GetVerifiedProps } from './GetVerified.types'

const LazyComponent = lazy(async () => await import('./GetVerified'))

export const GetVerified = (props: JSX.IntrinsicAttributes & GetVerifiedProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default GetVerified
