import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectRoot } from '01-atoms/RedirectRoot/RedirectRoot'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Invite as GatedComponent } from './Invite.lazy'
import { type InviteProps } from './Invite.types'

export const Invite: FC<InviteProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Invite.gated.tsx' />

    if (!appContext.isPublic) return <RedirectRoot />

    return <GatedComponent {...props} />
}

export default Invite
