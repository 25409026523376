import { type FC, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { getDebugger } from '20-lib/debug'

import { type ScrollToTopProps } from './ScrollToTop.types'

const debug = getDebugger('particles:ScrollToTop:ScrollToTop')

export const ScrollToTop: FC<ScrollToTopProps> = () => {
    const location = useLocation()
    const [_location, setLocation] = useState<string>(location.pathname)

    useEffect(() => {
        setLocation(location.pathname)
    }, [location])

    useEffect(() => {
        debug.log(Date.now(), 'Up and at them!', _location)

        window.scrollTo(0, 0)
    }, [_location])

    return null
}

export default ScrollToTop
