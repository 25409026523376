import React, { type FC } from 'react'

import { useNavigate } from 'react-router'

import { useAppState } from '20-lib/hooks'
import { useAuth } from '20-lib/hooks/auth'
import { clearAllToasts, toast } from '20-lib/toast'
import { MenuStore } from '20-lib/ui'

import { UserMenu } from '04-composites/UserMenu/UserMenu'

import { NavBar } from '05-materials/NavBar/NavBar'

import { menuItems } from './AuthenticatedNavBar.menus'
import { type AuthenticatedNavBarProps } from './AuthenticatedNavBar.types'

export const AuthenticatedNavBar: FC<AuthenticatedNavBarProps> = () => {
    const appContext = useAppState()
    const FilipinaLuvLib = useAuth()

    const navigate = useNavigate()

    // const [_showMoreDropdown, setShowMoreDropdown] = useState(false)

    const logout = (): void => {
        clearAllToasts()

        toast.success('Logging you out...')

        FilipinaLuvLib.logout()

        setTimeout(() => {
            navigate('/')
        }, 1000)
    }

    const baseMenu = new MenuStore()

    if (!appContext.requireProfile && !appContext.requireActivation) {
        baseMenu.addItem(menuItems.default.search)
        baseMenu.addItem(menuItems.default.chats)
        baseMenu.addItem(menuItems.default.likes)
        baseMenu.addItem(menuItems.default.visitors)
    }

    const primaryMenuItems = new MenuStore(baseMenu.getItems())

    if (!appContext.requireProfile && !appContext.requireActivation) {
        primaryMenuItems.addItem(menuItems.default.notificationsMenu)
    }

    const userMenuItems = new MenuStore()

    if (!appContext.requireProfile && !appContext.requireActivation) {
        if (appContext.isGuy || appContext.isAuthenticatedAdmin) {
            userMenuItems.addItem(menuItems.groupA.myProfile)
            userMenuItems.addItem(menuItems.groupA.datingTips)
        }

        if (appContext.isNotGuy || appContext.isAuthenticatedAdmin) {
            userMenuItems.addItem(menuItems.groupB.myProfile)
            userMenuItems.addItem(menuItems.groupB.datingTips)
            userMenuItems.addItem(menuItems.groupB.kaPartner)
            userMenuItems.addItem(menuItems.groupB.invite)
        }

        userMenuItems.addItem(menuItems.default.credits)

        if (appContext.isGuy || appContext.isAuthenticatedAdmin) {
            userMenuItems.addItem(menuItems.groupA.plans)
            userMenuItems.addItem(menuItems.groupA.premium)
            userMenuItems.addItem(menuItems.groupA.executive)
        }

        userMenuItems.addItem(menuItems.default.divider)
    }

    if (appContext.isAuthenticatedAdmin) {
        userMenuItems.addItem(menuItems.groupC.admin)

        userMenuItems.addItem(menuItems.default.divider)
    }

    userMenuItems.addItem(menuItems.default.settings)

    userMenuItems.addItem(menuItems.default.divider)

    userMenuItems.addItem({
        type: 'link',
        link: '#',
        title: <>Logout</>,
        onClick: () => {
            logout()
        }
    })

    primaryMenuItems.addItem({
        type: 'component',
        children: <UserMenu title={<>{appContext.userInfo.userName ?? 'Me'}</>} menuItems={userMenuItems.getItems()} />
    })

    const dropdownMenuItems = new MenuStore(baseMenu.getItems())

    if (!appContext.requireProfile && !appContext.requireActivation) {
        dropdownMenuItems.addItem(menuItems.default.divider)
        dropdownMenuItems.addItem(menuItems.default.notificationsLink)
    }

    dropdownMenuItems.addItem(menuItems.default.divider)
    dropdownMenuItems.pileItems(userMenuItems.getItems())

    // if (_showMoreDropdown) {
    //     dropdownMenuItems.addItem(menuItems.default.divider)
    //     dropdownMenuItems.pileItems(userMenuItems.getItems())
    // } else {
    //     dropdownMenuItems.addItem(menuItems.default.divider)

    //     dropdownMenuItems.addItem({
    //         type: 'link',
    //         link: '#',
    //         title: <>More</>,
    //         onClick: () => {
    //             setShowMoreDropdown(true)
    //         }
    //     })

    //     dropdownMenuItems.addItem(menuItems.default.divider)

    //     dropdownMenuItems.addItem({
    //         type: 'link',
    //         link: '#',
    //         title: <>Logout</>,
    //         onClick: () => {
    //             logout()
    //         }
    //     })
    // }

    return <NavBar primaryMenuItems={primaryMenuItems.getItems()} dropdownMenuItems={dropdownMenuItems.getItems()} />
}

export default AuthenticatedNavBar
