import { getRandomCSSColour } from '20-lib/ui/colours'

interface FLDebuggerNamespaces {
    block: RegExp[]
    allow: RegExp[]
}

interface FLDebuggerOpts {
    colorize?: boolean
}

type FLDebuggerOutput = (...args: unknown[]) => void
type FLDebuggerOutputs = FLDebuggerOutput[]

const flStandardOutputs = [
    (...message: unknown[]) => {
        console.log(...message)
    }
]

export class FLDebugger {
    protected static bootstrapping = false
    protected static colorize = typeof window === 'object'
    protected static debugVar = ''
    protected static namespaces: FLDebuggerNamespaces = { block: [], allow: [] }
    protected static namespaceACLs: Map<string, boolean> = new Map<string, boolean>()
    protected static outputs: FLDebuggerOutputs = flStandardOutputs
    private readonly instancePrefix: string
    private static lastCallTS: number
    private readonly instanceColor: string

    constructor(instancePrefix: string, opts?: FLDebuggerOpts) {
        this.instancePrefix = instancePrefix
        this.instanceColor = FLDebugger.getRandomColor()

        FLDebugger.colorize = opts?.colorize ?? FLDebugger.colorize
    }

    static bootstrap(namespaces: string | null | undefined): void {
        if (FLDebugger.bootstrapping) throw new Error('Already bootstrapping')

        FLDebugger.bootstrapping = true

        const loadNamespaces = namespaces ?? ''

        if (FLDebugger.debugVar !== loadNamespaces) {
            FLDebugger.debugVar = loadNamespaces

            const debugPatterns = FLDebugger.debugVar.split(/[\s,]+/).filter((c) => c !== '')

            for (const debugPattern of debugPatterns) {
                const regexified = debugPattern.replace(/\*/g, '.*?')

                if (debugPattern.startsWith('-')) {
                    FLDebugger.namespaces.block.push(new RegExp(`^${regexified.substring(1)}$`))
                } else {
                    FLDebugger.namespaces.allow.push(new RegExp(`^${regexified}$`))
                }
            }

            FLDebugger.namespaceACLs.clear()
        }

        FLDebugger.bootstrapping = false
    }

    static disableColors(): void {
        FLDebugger.colorize = false
    }

    static getAllowedNamespaces(): FLDebuggerNamespaces['allow'] {
        return FLDebugger.namespaces.allow
    }

    static getBlockedNamespaces(): FLDebuggerNamespaces['block'] {
        return FLDebugger.namespaces.block
    }

    static getNamespaces(): FLDebuggerNamespaces {
        return FLDebugger.namespaces
    }

    static getRandomColor(): string {
        return getRandomCSSColour()
    }

    static addOutput(handler: FLDebuggerOutput): void {
        FLDebugger.outputs.push(handler)
    }

    protected canLog(namespace: string): boolean {
        if (FLDebugger.namespaceACLs.has(namespace)) return FLDebugger.namespaceACLs.get(namespace) as boolean

        if (FLDebugger.namespaces.block.some((b) => b.test(namespace))) {
            FLDebugger.namespaceACLs.set(namespace, false)
        } else if (FLDebugger.namespaces.allow.some((b) => b.test(namespace))) {
            FLDebugger.namespaceACLs.set(namespace, true)
        } else {
            FLDebugger.namespaceACLs.set(namespace, false)
        }

        return FLDebugger.namespaceACLs.get(namespace) as boolean
    }

    getNamespace(): string {
        return this.instancePrefix
    }

    getNamespaceACLs(): Map<string, boolean> {
        return FLDebugger.namespaceACLs
    }

    extend(extendNamespace: string, opts?: FLDebuggerOpts): FLDebugger {
        return new FLDebugger([this.instancePrefix, extendNamespace].join(':'), opts)
    }

    private getFormatTypes(args: unknown[]): string {
        return args
            .map((e) => {
                if (typeof e === 'string') return '%s'
                if (typeof e === 'number') return '%d'
                return '%o'
            })
            .join(' ')
    }

    log(...args: unknown[]): boolean {
        if (this.canLog(this.instancePrefix)) {
            const message = FLDebugger.colorize
                ? [
                      `%c${this.instancePrefix}%c: ${this.getFormatTypes(args)} %c${FLDebugger.getDelay()}%c`,
                      `color: ${this.instanceColor}`,
                      'color: revert',
                      ...args,
                      `color: ${this.instanceColor}`,
                      'color: revert'
                  ]
                : [this.instancePrefix + ':', ...args, FLDebugger.getDelay()]

            Object.values(FLDebugger.outputs).forEach((output) => {
                output(...message)
            })

            return true
        }

        return false
    }

    static getDelay(): string {
        if (FLDebugger.lastCallTS == null) FLDebugger.lastCallTS = Date.now()

        const newTS = Date.now()
        const delta = newTS - FLDebugger.lastCallTS
        FLDebugger.lastCallTS = newTS
        return `+${delta}ms`
    }
}

if (window.flWebConfig?.flDebug != null) FLDebugger.bootstrap(window.flWebConfig.flDebug)
