import { type IConfig, type IFLWebConfig } from '20-types/fl/config'

import { parseValidLoginMethods, safeURLJoin } from './utils'

export const getConfig = async (): Promise<IConfig> => {
    return await new Promise((resolve) => {
        const interval = setInterval(() => {
            if (window.flWebConfig != null) {
                clearInterval(interval)

                resolve(generateConfig(window.flWebConfig))
            }
        }, 50)
    })
}

const generateBackendUrl = (flWebConfig?: IFLWebConfig): string => {
    if (flWebConfig?.backendUrl != null) return flWebConfig?.backendUrl

    if (typeof flWebConfig?.apiUrl === 'string' && flWebConfig?.apiUrl?.startsWith('http')) return flWebConfig?.apiUrl?.split('/').splice(0, 3, '').join('/')

    return safeURLJoin(flWebConfig?.apiUrl, 'https://backend.filipinaluv.com')
}

export const generateConfig = (flWebConfig?: IFLWebConfig): IConfig => ({
    api: {
        apiUrl: safeURLJoin(flWebConfig?.apiUrl, 'https://backend.filipinaluv.com/filipinaluv/api/'),
        headers: {
            'Content-Type': 'application/json'
        }
    },
    auth: {
        validLoginMethods: parseValidLoginMethods(flWebConfig?.validLoginMethods ?? ['email'])
    },
    geo: {
        key: flWebConfig?.googleKey ?? '',
        cache: {
            timeout: flWebConfig?.geoCacheTimeout ?? 3600
        },
        url: safeURLJoin(flWebConfig?.geoApiUrl, 'https://geo-api.filipinaluv.com')
    },
    maps: {
        google: {
            key: flWebConfig?.googleMapsKey ?? ''
        }
    },
    paypal: {
        options: {
            clientId: flWebConfig?.paypalClientId ?? '',
            currency: 'USD'
        }
    },
    search: {
        defaults: {
            page: 1,
            recordsPerPage: 48
        }
    },
    social: {
        facebook: {
            key: flWebConfig?.facebookKey ?? '',
            scope: 'public_profile,user_location,user_gender,user_birthday,email',
            version: 'v13.0'
        },
        google: {
            key: flWebConfig?.googleKey ?? '',
            scope: 'email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read'
        }
    },
    urls: {
        apiUrl: safeURLJoin(flWebConfig?.apiUrl, 'https://backend.filipinaluv.com/filipinaluv/api/'),
        backendUrl: generateBackendUrl(flWebConfig),
        imagesUrl: safeURLJoin(flWebConfig?.imagesUrl, 'https://images.filipinaluv.com'),
        rawImagesUrl: safeURLJoin(flWebConfig?.rawImagesUrl, 'https://images.filipinaluv.com/raw'),
        resizeImagesUrl: safeURLJoin(flWebConfig?.resizeImagesUrl, 'https://images.filipinaluv.com/resize')
    },
    poller: {
        interval: 5000
    },
    images: {
        minUploadSize: 1024 * 16,
        maxUploadSize: Math.pow(1024, 2) * 5
    },
    misc: {
        googleAnalyticsId: flWebConfig?.googleAnalyticsId ?? ''
    }
})
