import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type PurchaseProps } from './Purchase.types'

const LazyComponent = lazy(async () => await import('./Purchase'))

export const Purchase = (props: JSX.IntrinsicAttributes & PurchaseProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Purchase
