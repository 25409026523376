import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type ForgotPasswordProps } from './ForgotPassword.types'

const LazyComponent = lazy(async () => await import('./ForgotPassword'))

export const ForgotPassword = (props: JSX.IntrinsicAttributes & ForgotPasswordProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default ForgotPassword
