import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type FAQProps } from './FAQ.types'

const LazyComponent = lazy(async () => await import('./FAQ'))

export const FAQ = (props: JSX.IntrinsicAttributes & FAQProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

// eslint-disable-next-line react-refresh/only-export-components
export default FAQ
