import { type Dispatch, type SetStateAction } from 'react'

import { type ProfileBriefModel, type ProfileInfoModel } from '20-types/fl/database'

import { isEmpty } from '../validators'

export const changePage = (delta: number, _page: number, setPage: Dispatch<SetStateAction<number>>): void => {
    let page = 1

    if ((delta < 0 && _page + delta > 1) || delta > 0) {
        page = _page + delta
    }

    setPage(page)
}

export const getLocationFromPerson = (person: ProfileBriefModel | ProfileInfoModel): string => {
    if (!isEmpty(person.location)) {
        return person.location
    } else if (!isEmpty(person.city)) {
        return person.city
    } else {
        return ''
    }
}

export const getRedirectTarget = (pollStatus: string): string => {
    if (pollStatus === 'INCOMPLETEPROFILE') {
        return '/sign-up-create-unique-profile'
    } else if (pollStatus === 'INACTIVEPROFILE') {
        return '/sign-up-step-3'
    } else return ''
}
