import React, { type FC } from 'react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import actionButtonStyles from '10-styles/ActionButton.module.css'

import styles from './ReportBlockButton.module.css'
import { type ReportBlockButtonProps } from './ReportBlockButton.types'

export const ReportBlockButton: FC<ReportBlockButtonProps> = ({ onClick }) => (
    <div className={clsx(actionButtonStyles.ActionButtonWrapper, styles.ActionButtonWrapper)}>
        <button className={clsx(actionButtonStyles.ActionButton, styles.ReportBlockButton)} onClick={onClick}>
            <FontAwesomeIcon
                icon={faTimes}
                className={clsx(actionButtonStyles.ActionButtonIcon, styles.ReportBlockButtonIcon)}
                aria-hidden='true'
                aria-label='Block User'
                transform='grow-3'
            />
        </button>
    </div>
)

export default ReportBlockButton
