import { stateMachine } from 'pretty-state-machine'

import { type APIResultError, type APIResultOK } from '20-types/fl'

type FetchEncodeOpts = Partial<RequestInit> | { body?: object }

export const fetchEncode = (opts: FetchEncodeOpts): RequestInit => {
    const result = opts

    if (result.body != null && typeof result.body === 'object') result.body = JSON.stringify(result.body)

    return result as RequestInit
}

export const checkAuthError = <T extends APIResultOK>(result: T | APIResultError): void => {
    if (result.result === 'ERROR' && result.message === 'Not Authenticated') {
        // debug.log('checkAuthError', 'AuthCode:', result.authCode)
        stateMachine.pub({ isAuthenticated: false })
    }
}

export const generateAPIErrorObject = (msg: string): APIResultError => ({
    result: 'ERROR',
    code: 'ERROR',
    message: msg
})
