import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SignupStep2Props } from './SignupStep2.types'

const LazyComponent = lazy(async () => await import('./SignupStep2'))

export const SignupStep2 = (props: JSX.IntrinsicAttributes & SignupStep2Props): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default SignupStep2
