import React, { type FC, useEffect, useMemo, useState } from 'react'

import { generateConfig, getConfig } from '20-lib/config'
import { type IConfig } from '20-types/fl/config'

import LoadingPage from '05-materials/LoadingPage/LoadingPage'

import { ConfigContext } from './Config.context'
import { type ConfigProviderProps } from './ConfigProvider.types'

export const ConfigProvider: FC<ConfigProviderProps> = ({ children, config }) => {
    const [_config, setConfig] = useState<IConfig>(config != null ? generateConfig(config) : generateConfig())
    const [_configured, setConfigured] = useState<boolean>(config != null)

    useEffect(() => {
        if (config == null) {
            void getConfig().then((loadedConfig) => {
                setConfig(loadedConfig)
                setConfigured(true)
            })
        }
    }, [config])

    const passedConfigState = useMemo(
        () => ({
            Config: _config,
            Configured: _configured
        }),
        [_config, _configured]
    )

    if (!_configured) return <LoadingPage source='ConfigProvider.tsx' />

    console.warn('ConfigProvider configured')

    return <ConfigContext.Provider value={passedConfigState}>{children}</ConfigContext.Provider>
}

export default ConfigProvider
