import { type ConstantENUM, type ENUMType } from '20-types/fl/misc'

export const getAsNumberOrString = (val: ENUMType): ENUMType => {
    return typeof val === 'string' && /^-?\d+(\.\d+)?$/.test(val) ? Number(val) : val
}

export const mkEnum = (obj: ConstantENUM): ConstantENUM => {
    return Object.entries(obj).reduce((c, [k, v]) => {
        const trueK: ENUMType = getAsNumberOrString(k)
        const trueV: ENUMType = getAsNumberOrString(v)

        return { ...c, [trueK]: trueV, [trueV]: trueK }
    }, {})
}
