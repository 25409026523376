import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectLogin } from '01-atoms/RedirectLogin/RedirectLogin'
import { RedirectSignupStep2 } from '01-atoms/RedirectSignupStep2/RedirectSignupStep2'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Settings as GatedComponent } from './Settings.lazy'
import { type SettingsProps } from './Settings.types'

export const Settings: FC<SettingsProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Settings.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectLogin />

    if (appContext.requireProfile) return <RedirectSignupStep2 />

    return <GatedComponent {...props} />
}

export default Settings
