import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type SitemapProps } from './Sitemap.types'

const LazyComponent = lazy(async () => await import('./Sitemap'))

export const Sitemap = (props: JSX.IntrinsicAttributes & SitemapProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default Sitemap
