import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type DatingProfileProps } from './DatingProfile.types'

const LazyComponent = lazy(async () => await import('./DatingProfile'))

export const DatingProfile = (props: JSX.IntrinsicAttributes & DatingProfileProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default DatingProfile
