import React, { type FC } from 'react'

import { Outlet } from 'react-router'
import { ToastContainer } from 'react-toastify'

import { useAppState } from '20-lib/hooks'

import { Conditional } from '01-atoms/Conditional/Conditional'

import { ScrollToTop } from '03-particles/ScrollToTop/ScrollToTop'

import { AuthenticatedNavBar } from '05-materials/AuthenticatedNavBar/AuthenticatedNavBar'
import { Footer } from '05-materials/Footer/Footer'
import Notifier from '05-materials/Notifier/Notifier'
import { PublicNavBar } from '05-materials/PublicNavBar/PublicNavBar'
import { Tips } from '05-materials/Tips/Tips'

// const debug = getDebugger('layouts:MainLayout:MainLayout')
// import { getDebugger } from '20-lib/debug'
import { type MainLayoutProps } from './MainLayout.types'

export const MainLayout: FC<MainLayoutProps> = () => {
    const appContext = useAppState()

    return (
        <>
            <ToastContainer style={{ width: '450px' }} limit={3} autoClose={4000} pauseOnFocusLoss={false} />
            <Conditional condition={!appContext.isAuthenticated}>
                <PublicNavBar />
            </Conditional>
            <Conditional condition={appContext.isAuthenticated}>
                <AuthenticatedNavBar />
                <Tips />
                <Notifier />
            </Conditional>
            <ScrollToTop />
            <Outlet />
            <Footer />
        </>
    )
}

export default MainLayout
