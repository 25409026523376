import React, { lazy, Suspense } from 'react'

import { Loading } from '02-molecules/Loading/Loading'

import { type DatingTipsWorldProps } from './DatingTipsWorld.types'

const LazyComponent = lazy(async () => await import('./DatingTipsWorld'))

export const DatingTipsWorld = (props: JSX.IntrinsicAttributes & DatingTipsWorldProps): JSX.Element => (
    <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
    </Suspense>
)

export default DatingTipsWorld
