import { mutate } from 'swr'
import { coerceString } from '20-lib/utils'
import { type APIResultError, type APIResultOK } from '20-types/fl'

import { checkAuthError, coerceURL, fetchEncode, generateAPIErrorObject } from '.'

interface GlobalAuthToken {
    flAuthToken: string
}

declare global {
    interface Window extends GlobalAuthToken {}

    // eslint-disable-next-line no-var
    var flAuthToken: string // NOSONAR
}

const shouldSendAuthToken = (urlInput: RequestInfo | URL): boolean => {
    try {
        const url = coerceURL(urlInput)

        return url.hostname === 'localhost' || url.hostname === '127.0.0.1' || url.hostname.startsWith('192.168.') || url.hostname.endsWith('.filipinaluv.com')
    } catch (err) {
        return false
    }
}

export const fetcher = async <T extends APIResultOK>(input: RequestInfo | URL, init?: RequestInit): Promise<T | APIResultError> => {
    if (!/https?:/.test(coerceString(input))) {
        throw new Error('Invalid URL')
    }

    if (shouldSendAuthToken(input)) {
        const authToken = window.flAuthToken ?? localStorage.getItem('flAuthToken') ?? localStorage.getItem('authToken') ?? ''

        if (authToken != null && authToken !== '') {
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `1:${authToken}`
                }
            }
        }
    }

    try {
        const result = await ((await window.fetch(input, init)).json() as Promise<T | APIResultError>)

        checkAuthError<T>(result)

        // Automatically update the SWR cache for the input URL
        if (!('error' in result)) {
            // Ensure there is no error in the result
            mutate(input.toString(), result, { revalidate: false })
        }

        return result
    } catch (err) {
        const errorResult = generateAPIErrorObject((err as Error).message)

        return errorResult
    }
}

export const getter = async <T extends APIResultOK>(input: RequestInfo | URL, init?: RequestInit): Promise<T | APIResultError> => {
    if (init?.method !== 'GET') {
        init = {
            ...init,
            method: 'GET'
        }
    }

    return await fetcher<T>(input, init)
}

export const patcher = async <T extends APIResultOK>(input: RequestInfo | URL, init?: RequestInit): Promise<T | APIResultError> => {
    if (init?.method !== 'PATCH') {
        init = {
            ...init,
            method: 'PATCH'
        }
    }

    return await fetcher<T>(input, init)
}

export const pusher = async <T extends APIResultOK>(input: RequestInfo | URL, init?: RequestInit): Promise<T | APIResultError> => {
    if (init?.method !== 'POST') {
        init = {
            ...init,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }

    return await fetcher<T>(input, init)
}

export const putter = async <T extends APIResultOK>(input: RequestInfo | URL, init?: RequestInit): Promise<T | APIResultError> => {
    if (init?.method !== 'PUT') {
        init = {
            ...init,
            method: 'PUT'
        }
    }

    return await fetcher<T>(input, init)
}

export const getWithParams = async <T extends APIResultOK>(input: RequestInfo | URL, params?: Record<string, string>, init?: RequestInit): Promise<T | APIResultError> => {
    const url = coerceURL(input)

    if (params != null) {
        for (const key in params) {
            url.searchParams.set(key, params[key])
        }
    }

    return await getter<T>(url, init)
}

export const patchWithParams = async <T extends APIResultOK>(input: RequestInfo | URL, params?: object, init?: RequestInit): Promise<T | APIResultError> => {
    if (params != null) {
        init = fetchEncode({
            ...init,
            body: params
        })
    }

    return await patcher<T>(input, init)
}

export const postWithParams = async <T extends APIResultOK>(input: RequestInfo | URL, params?: object, init?: RequestInit): Promise<T | APIResultError> => {
    if (params != null) {
        init = fetchEncode({
            ...init,
            body: params
        })
    }

    return await pusher<T>(input, init)
}

export const putWithParams = async <T extends APIResultOK>(input: RequestInfo | URL, params?: object, init?: RequestInit): Promise<T | APIResultError> => {
    if (params != null) {
        init = fetchEncode({
            ...init,
            body: params
        })
    }

    return await putter<T>(input, init)
}
