import { useMemo, useState, useCallback } from 'react'

import stateMachine from 'pretty-state-machine'
import useSWR from 'swr'

import { mappings } from '20-lib/config'
import { Constants } from '20-lib/constants'
import { getDebugger } from '20-lib/debug'
import { type APIUserProfilesSearchProfilesResult } from '20-types/fl'
import { DefaultSearchFilter } from '20-lib/constants/common'

import { useAppState } from '../app'
import { useGeoData } from '../geoData'

import { type ResetSearchArgs, type SearchParams, type UseSearchServiceHook, type SearchingForRelationship, type GenderInterest } from './types'
import { updateKeys, useSearchRequestParams } from './utils'

import { useBackendURLWithParams } from '..'

const debug = getDebugger('lib:hooks:search')

export const useSearchService = (): UseSearchServiceHook => {
    const appState = useAppState()

    const geoData = useGeoData()

    const [_searchInterest, setInterest] = useState(stateMachine.get<GenderInterest>('interested', appState.interest))
    const [_searchSearchingFor, setSearchingFor] = useState(stateMachine.get<SearchingForRelationship>('searchingFor', appState.searchingFor))
    const [_searchLowAge, setLowAge] = useState(stateMachine.get<number>('lowAge', appState.userInfo.lowAge ?? DefaultSearchFilter.lowAge))
    const [_searchHighAge, setHighAge] = useState(stateMachine.get<number>('highAge', appState.userInfo.highAge ?? DefaultSearchFilter.highAge))
    const [_pages, setPages] = useState(1)
    const [_recordsPerPage, setRecordsPerPage] = useState(Constants.Defaults.RecordsPerPage.SEARCH)

    const [_dataSource, setDataSource] = useState('SearchBrowse')

    const compiledSearchRequestParams = useSearchRequestParams({
        countryName: geoData.country_name ?? '',
        interested: _searchInterest,
        searchingFor: _searchSearchingFor,
        lowAge: _searchLowAge,
        highAge: _searchHighAge,
        page: 1,
        recordsPerPage: _pages * Constants.Defaults.RecordsPerPage.SEARCH
    })

    const [serviceType, serviceEndpoint] = (mappings[_dataSource] ?? 'profiles/searchprofiles').apiHandle.split('/')

    const backendSearchURL = useBackendURLWithParams(serviceType, serviceEndpoint, compiledSearchRequestParams)

    const searchProfilesResult = useSWR<APIUserProfilesSearchProfilesResult>(appState.isAuthenticated ? backendSearchURL : null)

    const searchProfiles = useMemo(
        () => (searchProfilesResult.data?.profiles != null && Array.isArray(searchProfilesResult.data?.profiles) ? searchProfilesResult.data?.profiles : []),
        [searchProfilesResult.data?.profiles]
    )
    const searchProfileIds = useMemo(
        () =>
            searchProfilesResult.data?.profiles != null && Array.isArray(searchProfilesResult.data?.profiles)
                ? searchProfilesResult.data?.profiles.map((profile) => profile.profileId)
                : [],
        [searchProfilesResult.data?.profiles]
    )

    const resetSearch = useCallback(
        (resetSearchArgs?: ResetSearchArgs): void => {
            const { dataSource, recordsPerPage } = resetSearchArgs ?? {}

            setInterest(appState.interest)
            setSearchingFor(appState.searchingFor)
            setLowAge(appState.userInfo.lowAge ?? Constants.Defaults.SearchFilter.lowAge)
            setHighAge(appState.userInfo.highAge ?? Constants.Defaults.SearchFilter.highAge)
            setPages(1)
            setRecordsPerPage(recordsPerPage ?? Constants.Defaults.RecordsPerPage.SEARCH)
            setDataSource(dataSource ?? 'SearchBrowse')
        },
        [
            appState.userInfo.lfSerious,
            appState.userInfo.lfRelationship,
            appState.userInfo.lfCasual,
            appState.userInfo.lfFriendship,
            appState.userInfo.lookingForGirl,
            appState.userInfo.lookingForGuy,
            appState.userInfo.lookingForGenderQueer,
            appState.userInfo.lowAge,
            appState.userInfo.highAge
        ]
    )

    const updateSearch = (updateState: Partial<SearchParams>): void => {
        updateState = updateState ?? {}

        if (updateState.interested != null) setInterest(updateState.interested)
        if (updateState.searchingFor != null) setSearchingFor(updateState.searchingFor)
        if (updateState.lowAge != null) setLowAge(updateState.lowAge)
        if (updateState.highAge != null) setHighAge(updateState.highAge)

        if (Object.keys(updateState).filter((key) => updateKeys.includes(key)).length > 0) setPages(1)
    }

    const loadMore = (): void => {
        debug.log('loading more')
        setPages((page) => page + 1)
    }

    const searchService = useMemo(() => {
        const poll = async (): Promise<void> => {
            await searchProfilesResult.mutate()
        }

        const getPreviousProfileId = (profileId: number): number | null => {
            const profileIdPos = searchProfileIds.indexOf(profileId)

            return searchProfileIds[profileIdPos - 1] != null ? searchProfileIds[profileIdPos - 1] : null
        }

        const getNextProfileId = (profileId: number): number | null => {
            const profileIdPos = searchProfileIds.indexOf(profileId)

            return searchProfileIds[profileIdPos + 1] != null ? searchProfileIds[profileIdPos + 1] : null
        }

        return {
            loadMore,
            getPreviousProfileId,
            getNextProfileId,
            resetSearch,
            updateSearch,
            setDataSource,
            setRecordsPerPage,
            poll,
            searchProfiles,
            searchProfileIds,
            loading: searchProfilesResult.isLoading,
            validating: searchProfilesResult.isValidating,
            searchInterest: _searchInterest,
            searchSearchingFor: _searchSearchingFor,
            searchLowAge: _searchLowAge,
            searchHighAge: _searchHighAge,
            pages: _pages
        }
    }, [
        _pages,
        _searchHighAge,
        _searchInterest,
        _searchLowAge,
        _searchSearchingFor,
        searchProfileIds,
        searchProfiles,
        searchProfilesResult,
        resetSearch,
        updateSearch,
        setDataSource,
        setRecordsPerPage
    ])

    return searchService
}
