import React, { type FC } from 'react'

import clsx from 'clsx'

import BrandingStyles from '10-styles/App/Branded.module.css'

import { type BrandedWordLuvProps } from './BrandedWordLuv.types'

export const BrandedWordLuv: FC<BrandedWordLuvProps> = ({ capitalized }) => {
    let displayText = 'Luv'

    if (capitalized === true) {
        displayText = displayText.toUpperCase()
    }

    return <span className={clsx(BrandingStyles.Branded, BrandingStyles.Luv)}>{displayText}</span>
}

export default BrandedWordLuv
