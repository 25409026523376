import React, { Component, type ReactNode } from 'react'

import { getDebugger } from '20-lib/debug'

import { type ErrorBoundaryWrapperHasError, type ErrorBoundaryWrapperProps, type ErrorBoundaryWrapperState } from './ErrorBoundaryWrapper.types'
import { boundaryNames } from './ErrorBoundaryWrapper.utils'

export class ErrorBoundaryWrapper extends Component<ErrorBoundaryWrapperProps, ErrorBoundaryWrapperState> {
    constructor(props: ErrorBoundaryWrapperProps | Readonly<ErrorBoundaryWrapperProps>) {
        super(props)

        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: unknown): ErrorBoundaryWrapperHasError {
        getDebugger('error-boundary').extend('getDerivedStateFromError').log(error)

        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: unknown, errorInfo: unknown): void {
        // You can also log the error to an error reporting service
        getDebugger('error-boundary').extend('componentDidCatch').log(error, errorInfo)
    }

    render(): ReactNode {
        if (this.state.hasError) {
            console.warn(`Unexpected problem in ${this.props.name}`)
            // You can render any custom fallback UI
            return (
                <>
                    <h1 className='centered'>An unexpected error occured.</h1>
                    <h2 className='centered'>Please go back to the previous page or try again later.</h2>
                    <h3 className='centered'>If the problem persists please contact support.</h3>
                    <h4 className='centered'>Error Boundary Name : {boundaryNames[this.props.name] ?? this.props.name}</h4>
                </>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundaryWrapper
