export type MappingOptions = Record<string, boolean>

export interface Mapping {
    apiHandle: string
    title: string
    options?: MappingOptions
}

export type Mappings = Record<string, Mapping>

export const mappings: Mappings = {
    liking: {
        apiHandle: 'profiles/getlikingprofiles',
        title: 'Likes Me'
    },
    mutual: {
        apiHandle: 'profiles/getmutuallikes',
        title: 'Mutual Likes'
    },
    mutualnotmessaged: {
        apiHandle: 'profiles/getmutualnotmessaged',
        title: 'Not Messaged Mutual Likes'
    },
    likes: {
        apiHandle: 'profiles/getlikedprofiles',
        title: 'My Likes'
    },
    visitors: {
        apiHandle: 'profiles/getprofilevisitors',
        title: 'My Visitors'
    },
    visited: {
        apiHandle: 'profiles/getvisitedprofiles',
        title: 'Visited Profiles'
    },
    SearchBrowse: {
        apiHandle: 'profiles/searchprofiles',
        title: 'Find Love',
        options: {
            likeButton: true,
            reportButton: true
        }
    }
}

export default mappings
