import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectLogin } from '01-atoms/RedirectLogin/RedirectLogin'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { Admin as GatedComponent } from './Admin.lazy'
import { type AdminProps } from './Admin.types'

export const Admin: FC<AdminProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='Admin.gated.tsx' />

    if (!appContext.isAuthenticatedAdmin) return <RedirectLogin />

    return <GatedComponent {...props} />
}

export default Admin
