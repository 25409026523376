import React, { type FC } from 'react'

import { useAppState } from '20-lib/hooks'

import { RedirectRoot } from '01-atoms/RedirectRoot/RedirectRoot'

import { LoadingPage } from '05-materials/LoadingPage/LoadingPage'

import { SocialShare as GatedComponent } from './SocialShare.lazy'
import { type SocialShareProps } from './SocialShare.types'

export const SocialShare: FC<SocialShareProps> = (props) => {
    const appContext = useAppState()

    if (!appContext.synchronized) return <LoadingPage source='SocialShare.gated.tsx' />

    if (!appContext.isAuthenticated) return <RedirectRoot />

    return <GatedComponent {...props} />
}

export default SocialShare
